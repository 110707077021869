// ::-webkit-scrollbar {
//   display: none;
// }

// *******landing-page**********//

.bg-image {
  background-image: url(./assets/images/skull1.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}

nav {
  height: 100%;
  border-bottom: 0.5px solid white;
  background-color: #06071b !important;

  .w60 {
    width: 72%;
  }
  .nav-container {
    position: relative;
    z-index: 2;
  }
  .navbar-toggler {
    border-color: white !important;
    .list-icon {
      color: white !important;
      font-size: 2rem;
    }
  }
  .drp-btn {
    font-family: "Roboto", sans-serif;
    button {
      height: 25px;
      background: #ef09a1de;
      color: white;
      border-radius: 0px;
      font-weight: bold;
      font-size: 0.875rem;
    }
    .dropdown-toggle::after {
      color: white;
      position: relative;
      top: 2px;
    }
    .dropdown-menu {
      border-radius: 20px;
      min-width: 4rem !important;
      &[data-bs-popper] {
        left: -5px !important;
      }
      .dropdown-item {
        font-size: 0.9rem;
      }
      a {
        border-radius: 30px;
        &:hover {
          background-color: #ef09a1de;
          color: white;
        }
      }
    }
  }

  .tri-logo {
    img {
      width: 150px;
      height: auto;
    }
  }

  .nav-list {
    .nav-list-items {
      a {
        color: white !important;
      }
    }
  }
  .hdr-list-items {
    cursor: pointer;
    font-size: 0.875rem;
    color: white !important;
    border: none;
    background: transparent;
    svg {
      display: block !important;
      width: 30px;
    }
  }
  .next {
    cursor: pointer;
    svg {
      display: block !important;
      width: 160px;
    }
  }
  .hdr-btn {
    height: 25px;
    background: #ef09a1de;
    color: white;
  }
}
.logo {
  span {
    color: white;
    font-size: 14px;
  }
}
.heading {
  position: relative;
  z-index: 1;
  height: 100vh;
  .image1 {
    width: 200.15px;
    height: 40.9px;
  }
  .logo {
    span {
      color: white;
      font-size: 1.2rem;
    }
  }

  .register-btn {
    width: 120px;
    height: 40px;
    background: #ef09a1de;
    font-size: 1.2rem;
    color: white;
  }
}
.information {
  position: relative;
  z-index: 2;
  top: -300px;
  background-image: url(./assets/images/twotriangle.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1260px;
}
.card-section {
  .up-events {
    cursor: pointer;
  }
}
section {
  .down-btn {
    p {
      color: white;
    }
    img {
      cursor: pointer;
      width: 50px;
      height: 50px;
    }
  }
  .point-image {
    img {
      position: relative;
      z-index: 1;
      // top: -150px;
      width: 220px;
    }
  }
  .what-is-this {
    position: relative;
    // top: -300px;
  }
  .wit-heading {
    color: white;
  }
  .video {
    // position: relative;
    // top: -1600px;
    // left: 190px;
  }
  .video-image {
    width: 60%;
    // height: 520px;
  }
  .play-btn {
    position: relative;
    transform: scaleX(-1);
    left: -340px;
    cursor: pointer;
  }
  .text1 {
    height: 15.37109375px;
    width: 148.27423095703125px;
  }

  .card {
    border: none;
    border-radius: 20px !important;
    background: transparent;
    width: 300px;

    .card-body {
      background: #3f1f55;
      font-family: "Roboto", sans-serif !important;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      .card-left {
        width: 17%;
      }
      .card-right {
        width: 83%;
      }
      .month {
        text-transform: uppercase;
        color: #ef09da;
        font-weight: bold;
        font-size: 2rem;
      }
      .date {
        font-size: 2rem;
        color: white;
        font-weight: bold;
        letter-spacing: -3px;
      }

      .card-title {
        color: white;
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: 1px;
      }
      .card-text {
        font-size: 1rem;
        color: #dfdddd;
        letter-spacing: 1px;
      }
    }
  }
}
.faqs {
  height: 100vh;
  font-family: "Inter", sans-serif;
  .accordion-heading {
    font-family: "Roboto", sans-serif;
    h1 {
      color: white;
      font-weight: bold;
    }
    p {
      color: white;
    }
  }
  .abcd {
    width: 52%;
    $midnight: #2c3e50;
    $clouds: #ecf0f1;

    h1 {
      margin: 0;
      line-height: 2;
      text-align: center;
      color: white;
    }
    h2 {
      margin: 0 0 0.5em;
      font-weight: normal;
    }
    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    /* Accordion styles */
    .tabs {
      border-radius: 0px;
      overflow: hidden;
      // box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
    }
    .tab {
      width: 100%;
      color: white;
      overflow: hidden;
      &-label {
        display: flex;
        justify-content: space-between;
        padding: 1em;
        background: #06071b;
        cursor: pointer;
        /* Icon */
        &:hover {
          background: #06071b;
        }
        &::after {
          content: "\276F";
          width: 1em;
          height: 1em;
          text-align: center;
          transition: all 0.35s;
        }
      }
      &-content {
        max-height: 0;
        padding: 0 1em;
        color: white;
        font-size: 0.8rem;
        background: #06071b;
        transition: all 0.35s;
      }
      &-close {
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        font-size: 0.75em;
        background: #06071b;
        cursor: pointer;
        &:hover {
          background: #06071b;
        }
      }
    }

    // :checked
    input:checked {
      + .tab-label {
        background: #06071b;
        &::after {
          transform: rotate(-90deg);
        }
      }
      ~ .tab-content {
        max-height: 100vh;
        padding: 1em;
        // color: rgb(10, 10, 10);
        color: white;
        font-size: 0.8rem;
      }
    }
  }
}
footer {
  position: relative;
  // top: -1500px;
  font-family: "Inter", sans-serif;
  border-top: 1px solid #82838d;
  .footer-heading {
    color: white;
    font-size: 1rem;
    .logo {
      color: white;
      text-decoration: none;
    }
  }
  p {
    color: #82838d;
    font-size: 1rem;
  }
  .description {
    font-size: 12px;
  }
  .list-unstyled {
    li a,
    span {
      cursor: pointer;
      color: #82838d;

      text-decoration: none;
    }
  }
  .subscribe-form {
    input {
      background: transparent;
      border-right: none;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    button {
      width: 50px;
      height: 50px;
      background: #ff7000;
      border-left: none;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
    }
  }
  .terms {
    cursor: pointer;
    li {
      background: transparent;
      border: none;
      color: white;
      font-size: 0.875rem;
    }
  }
  .social-icon {
    cursor: pointer;
    .social-icon-items {
      width: 40px;
      height: 40px;
      list-style: none;
      border: 2px solid #1f2032 !important;
      border-radius: 50%;
      background: transparent;
      border: none;
      color: white;
      a{
        color: white;
      }
    }
  }
}

//*****privacy-policy********//
.under-line::before {
  content: "";
  position: relative;
  display: block;
  left: 5px;
  top: 45px;
  // top: 0;
  width: 35%;
  height: 5px;
  background-color: #ef09a1de;
}
.under-line1::before {
  content: "";
  position: absolute;
  display: block;
  left: 15px;
  top: 35px;
  width: 15%;
  height: 5px;
  background-color: #ef09a1de;
}
.under-line2::before {
  content: "";
  position: relative;
  display: block;
  left: 30%;
  top: 45px;
  // top: 0;
  width: 40%;
  height: 5px;
  background-color: #ef09a1de;
}

.privacy {
  position: relative;
  .privacy-policy-heading {
    h1 {
      color: white !important;
      font-size: 2rem;
      font-family: "Roboto", sans-serif;
    }
  }
  font-family: "Roboto", sans-serif;
  background-image: url(./assets/images/big-triangle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  .privacy-description {
    .border-privacy {
      padding: 10px;
      border: 1px solid #ef09a1de;
    }

    height: auto;
    background: #884dff0d;
    p {
      color: rgba(255, 255, 255, 0.68);
      font-size: 0.75rem;
    }

    .privacy-list {
      .privacy-list-items {
        border: none;
        background: transparent;
        .privacy-heading {
          h3 {
            color: white;
          }
        }
        p {
          color: white;
        }
      }
    }
    .Subscription-Term-heading {
      h1 {
        color: white;
      }
    }
    .subscription-term-list {
      .subscription-term-list-items {
        border: none;
        background: transparent;
        .subscription-term-heading {
          h3 {
            color: white;
          }
        }
        p {
          color: white;
          margin-bottom: 0;
        }
      }
    }
  }
}
//*********chat-bot*******//

.chat-bot-dark {
  background-color: #06071b !important;
}
.chat-bot-light {
  background-color: white;
}

.chat-bot {
  height: calc(100vh - 80px);
  // overflow: auto;

  .answers {
    &::-webkit-scrollbar {
      display: none;
    }
    // position: relative;
    overflow: auto;
    // top: -50px;
  }
}

.chat {
  // height: 8vh;
}

.h1 {
  height: 100;
}
.dark-question {
  width: 67%;
  background-color: #311357;
  // height: 3rem;
  p {
    color: white;
    font-size: 1rem;
  }
}

.light-question {
  width: 67%;
  background: #05032059;
  // height: 3rem;
  p {
    color: white;
    font-size: 1rem;
  }
}

.yugen-logo-q {
  .username-dark {
    background-color: white;
    width: 40px;
    height: 40px;
  }
  .username-light {
    background-color: rgb(65, 64, 64);
    width: 40px;
    height: 40px;
    span {
      color: white;
    }
  }
  img {
    width: 40px;
    height: 40px;
  }
}
.dark-answer {
  width: 67%;

  background: linear-gradient(
    172.88deg,
    rgba(17, 215, 191, 0.17) 43.12%,
    rgba(17, 215, 191, 0) 231%
  );
  // height: 130px;

  p {
    color: white;
    font-size: 1rem;
  }
}

.light-answer {
  width: 67%;

  background: linear-gradient(
    172.88deg,
    rgba(135, 135, 135, 0.17) 43.12%,
    rgba(17, 215, 191, 0) 231%
  );
  // height: 130px;

  p {
    color: black;
    font-size: 1rem;
  }
}

.light-answer2 {
  background: linear-gradient(
    172.88deg,
    rgba(135, 135, 135, 0.17) 43.12%,
    rgba(17, 215, 191, 0) 231%
  );

  height: 130px;

  p {
    color: black;
    font-size: 0.75rem;
  }
}

.dark-answer2 {
  background: linear-gradient(
    172.88deg,
    rgba(17, 215, 191, 0.17) 43.12%,
    rgba(17, 215, 191, 0) 231%
  );

  height: 130px;

  p {
    color: white;
    font-size: 0.75rem;
  }
}
.yugen-logo-a {
  img {
    width: 40px;
    height: 40px;
  }
}
.chat-bot-list {
  .chat-bot-list-items {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    cursor: pointer;
    border: none;
    background: transparent;
    p {
      color: white;
      font-size: 0.938rem;
    }
  }
}

.chat-bot-list-light {
  .chat-bot-list-items {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    cursor: pointer;
    border: none;
    background: transparent;
    p {
      color: black !important;
      font-size: 0.938rem;
    }
    span {
      svg {
        .path2 {
          fill: white !important;
        }
        path {
          fill: black;
        }
      }
    }
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 5px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #e7e7e7;
  border-right: 1.1em solid #e7e7e7;
  border-bottom: 1.1em solid #e7e7e7;
  border-left: 1.1em solid #ef09a1de;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.type {
  height: 200px;
}
.loading-dots-dark {
  position: absolute;
  z-index: 5;
  right: 0;
  top: 40%;
  background: transparent;
  width: 50px;
  margin: auto;
  .loading {
    width: 7px;
    height: 7px;
    background: #fff;
    border-radius: 100%;
    float: left;
    margin-right: 5px;
  }
  .loading-0 {
    animation: bounce 1s infinite;
    animation-delay: 0.1s;
    background: #ffffff;
  }
  .loading-1 {
    animation: bounce 1s infinite;
    animation-delay: 0.3s;
    background: #ffffff;
  }
  .loading-2 {
    animation: bounce 1s infinite ease;
    animation-delay: 0.5s;
    background: #ffffff;
  }
}
.loading-dots-light {
  position: absolute;
  z-index: 115;
  right: 0;
  top: 40%;
  background: transparent;
  width: 50px;
  margin: auto;
  .loading {
    width: 7px;
    height: 7px;
    background: #ffffff;
    border-radius: 100%;
    float: left;
    margin-right: 5px;
  }
  .loading-0 {
    animation: bounce 1s infinite;
    animation-delay: 0.1s;
    background: #070000;
  }
  .loading-1 {
    animation: bounce 1s infinite;
    animation-delay: 0.3s;
    background: #020000;
  }
  .loading-2 {
    animation: bounce 1s infinite ease;
    animation-delay: 0.5s;
    background: #000000;
  }
}

@keyframes bounce {
  0%,
  100% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
}
.type-here-dark {
  width: 91%;
  height: 75px;
  background-color: #2d1252;
  .chat-box {
    position: relative;
    width: 93%;

    input {
      width: 90%;
      height: 54px;
      color: white;
      border-radius: 0px !important;
      background-color: #35145e;
      border: none;
      caret-color: white;
    }
  }
}
.yugen-logo-t {
  .username-dark {
    background-color: white;
    width: 53px;
    height: 53px;
  }
  .username-light {
    background-color: rgb(65, 64, 64);
    width: 53px;
    height: 53px;
    span {
      color: white;
    }
  }
}
.type-here-light {
  width: 91%;
  height: 75px;
  background-color: lightgrey;
  .chat-box {
    width: 93%;

    input {
      width: 90%;
      height: 54px;
      color: black;
      border-radius: 0px !important;
      background-color: lightgrey;
      border: none;
      caret-color: black;
    }
  }
}

//*******about-us*********//
.about-us {
  font-family: "Roboto", sans-serif;

  background-image: url(./assets/images/big-triangle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  .about-us-heading {
    h1 {
      color: white;
      font-size: 2rem;
      font-family: "Roboto", sans-serif;
    }
  }
  .description {
    height: 84vh;
    background: #884dff0d;
    .description-heading {
      border: 1px solid #ef09a1de;

      .about-us-icon {
        svg {
          display: block;
          width: 60px;
          height: 90px;
        }
      }
      .under-line::before {
        content: "";
        position: relative;
        display: block;
        left: 5px;
        bottom: -48px;
        // top: 0;
        width: 35%;
        height: 5px;
        background-color: #ef09a1de;
      }
      h1 {
        color: white;
        font-size: 2.3rem;
      }
      p {
        font-size: 0.9rem;
        color: white;
      }
    }
  }
}

code {
  counter-reset: line;
}

.code-line {
  counter-increment: line;
  position: relative;
  display: block;
  margin-left: 1.5rem;
}

.code-line:before {
  content: counter(line);
  position: absolute;
  margin-left: -1.5rem;
}
//********checkout******//
.payment {
  height: 100vh;
}
.payment-form {
  width: 600px;
  height: 200px;
}
.label-credit {
  color: #afafaf;
}
.payment-bg {
  background-color: #06071b;
  border-radius: 20px;
}

.modal-content {
  background-color: #06071b;
}
.modal-header {
  background: linear-gradient(#b700a5, #009d8a);
}
.modal-title {
  color: white;
  font-family: "Montserrat", sans-serif;
}

.btn-close {
  background-color: white;
}
.payment-title {
  // font-size: 10px;
  color: white;
  font-family: "Montserrat", sans-serif;
}
.subscription-field {
  background-color: transparent !important;
  // color: $white;
}
.payment-btn {
  button {
    font-family: "Lexend", sans-serif;
    background-color: white;
    border: 1px solid white;
    border-radius: 30px !important;
    &:hover {
      background-color: #06071b;
      color: white;
      font-weight: 900;
    }
    span {
      background-color: #e1bcff;
      background-clip: text;
      -webkit-text-stroke-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-background-clip: text;
      // color: black;
      font-weight: 900;
    }
  }
}
.card1 {
  background-color: white;
  padding: 10px 20px 11px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #afafaf;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}
.form-check-input:checked {
  background-color: rgba(239, 9, 161, 0.8705882353);
  border-color: rgba(239, 9, 161, 0.8705882353);
}
.form-check-input:focus {
  // border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(179, 179, 179, 0.25);
}
.pay-button {
  // padding: 0.7rem 2rem;
  width: 100%;
  // margin: 1rem 0;
  color: white;
  font-weight: bold;
  font-size: small;
  background-color: #141650;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  transition: box-shadow 500ms;
  cursor: pointer;
}
.pay-button:disabled {
  background-color: #afafaf;
  box-shadow: none;
  cursor: default;
}
.pay-button:disabled:hover {
  box-shadow: none;
}
.pay-button:hover {
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
}

//*****forgot*****//
.forgot-password-btn {
  background-color: #e1bcff;
  background-clip: text;
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-background-clip: text;
  color: black;
  font-weight: 900;
}
//*****Login*****//

.login-page {
  font-family: "Roboto", sans-serif;
  background-image: url(./assets/images/big-triangle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  .invalid-feedback {
    top: 42px;
    // left: 56px;
  }
  .login-heading {
    h1 {
      color: white;
      font-size: 2rem;
    }
  }
  .login-detail {
    height: 84vh;
    background: #884dff0d;
  }
  .log-board {
    border-radius: 50px;
    height: 100%;
    border: 5px solid #898c9f;
  }
  .profile-pic {
    svg {
      width: 70px;
      height: 70px;
    }
  }
  .input-group {
    border-radius: 30px !important;
    border: 1px solid #898c9f;

    .input-group-text {
      border-radius: 30px !important;
      height: 46px;
      width: 46px;
      border: none;
      background-color: #ef09a1;
      color: white;
    }
  }
  .user-name {
    border: none;
    border-radius: 30px;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    background: transparent;
    caret-color: white;
    color: white;
    &:focus {
      box-shadow: none;
    }
    &::placeholder {
      font-family: "Abyssinica SIL", serif;
    }
  }
  .password {
    border: none;
    border-radius: 30px;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    background: transparent;
    caret-color: white;
    color: white;
    &:focus {
      box-shadow: none;
    }
    background: transparent;
    &::placeholder {
      font-family: "Abyssinica SIL", serif;
    }
  }
  .forgot-password {
    .form-check {
      color: white;
      .form-check-input:checked {
        background-color: #ef09a1;
        border-color: #ef09a1;
      }
    }
    a {
      text-decoration: none;
      color: white;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .login-btn {
    button {
      height: 44px;
      font-family: "Abyssinica SIL", serif;
      background-color: #ef09a1;
      color: white;
      border: none;
    }
  }
}
//********contact-us******//

.contact-us {
  font-family: "Roboto", sans-serif;

  background-image: url(./assets/images/big-triangle.svg);
  background-size: 1100px;
  background-repeat: no-repeat;
  background-position: center;
  .contact-us-heading {
    h1 {
      color: white;
      font-size: 2rem;
    }
  }
  .contact-description {
    height: 650px;
    background: #884dff0d;
  }
}
//*******user-account*******//
.user-account {
  font-family: "Roboto", sans-serif;

  height: 700px;
  background-image: url(./assets/images/big-triangle.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .user-account-heading {
    h1 {
      color: white;
      font-size: 2rem;
    }
  }
  .user-account-description {
    height: 650px;
    background: #884dff0d;
  }
}

//******terms-conditions*****//

.terms-condition {
  &::-webkit-scrollbar {
    display: none;
  }

  .under-line {
    position: relative;
    display: inline-block;
  }
  .under-line1 {
    position: relative;
    display: inline-block;
  }
  .under-line1::before {
    // content: "";
    // position: relative;
    // display: block;
    // left: 0;
    // top: 27px;
    // // top: 0;
    // width: 13%;
    // height: 5px;
    // background-color: #ef09a1de;
    content: "";
    position: absolute;
    top: 22px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid rgb(251, 246, 246);
  }

  .under-line::before {
    // content: "";
    // position: relative;
    // display: block;
    // left: 0;
    // top: 29px;
    // width: 25%;
    // height: 5px;
    // background-color: #ef09a1de;
    content: "";
    position: absolute;
    top: 24px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid rgb(255, 255, 255);
  }

  // &::before {
  //   content: "";
  //   position: relative;
  //   display: block;
  //   left: 15px;
  //   bottom: -55px;
  //   // top: 0;
  //   width: 15%;
  //   height: 5px;
  //   background-color: #ef09a1de;
  // }
  font-family: "Roboto", sans-serif;
  background-image: url(./assets/images/big-triangle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  .terms-condition-heading {
    h1 {
      color: white;
      font-size: 1.5rem;
    }
  }
  .terms-condition-description {
    .border-term {
      padding: 10px;
      border: 1px solid #ef09a1de;
    }

    height: auto;
    background: #884dff0d;
    p {
      color: rgba(255, 255, 255, 0.68);
      font-size: 0.75rem;
    }

    .term-list {
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #6b529e;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px #a5a8d8;
      }
    }
    .term-list-items {
      .Subscription-Term-heading {
        h3 {
          color: white;
          font-size: 1.5rem;
        }
      }
      border: none;
      background: transparent !important;
      .term-heading {
        h3 {
          color: white;
          font-size: 1.3rem;
        }
      }
      p {
        color: white;
        margin-bottom: 0;
      }
    }
    .modal-term-list {
      // height: calc(100% - 500px) !important;
      height: 400px;
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #6b529e;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px #a5a8d8;
      }
      .term-list-items {
        .Subscription-Term-heading {
          h3 {
            color: white;
            font-size: 1.5rem;
          }
        }
        border: none;
        background: transparent !important;
        .term-heading {
          h3 {
            color: white;
            font-size: 1.3rem;
          }
        }
        p {
          color: white;
          margin-bottom: 0;
        }
      }
    }
    .Subscription-Term-heading {
      h1 {
        color: white;
        font-size: 1.5rem;
      }
    }
    .subscription-term-list {
      .subscription-term-list-items {
        border: none;
        background: transparent;
        .subscription-term-heading {
          h3 {
            color: white;
            font-size: 1.3rem;
          }
        }
        p {
          color: white;
          margin-bottom: 0;
        }
      }
    }
  }
}

//*****upcoming-events*******//
.upcoming-events {
  font-family: "Roboto", sans-serif;
  background-image: url(./assets/images/big-triangle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  .upcoming-events-heading {
    h1 {
      color: white;
      font-size: 2rem;
    }
  }
  .upcoming-events-box {
    height: 84vh !important;
    background: #884dff0d;
  }
}
//*******verify*****//
.border-radius-0 {
  border-radius: 0 !important;
}

.verify-box {
  height: 100vh !important;
  background: rgba(136, 77, 255, 0.0509803922);
}

.verify-btn {
  height: 44px;
  font-family: "Abyssinica SIL", serif;
  background-color: #ef09a1;
  color: white;
  border: none;
  border-radius: 45px;
}

.verify-btn:hover {
  background-color: #db1d9c !important;
  cursor: pointer;
}

//*******sign-up*****//

.sign-up {
  font-family: "Roboto", sans-serif;
  background-image: url(./assets/images/big-triangle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  .sign-up-heading {
    h1 {
      color: white;
      font-size: 2rem;
    }
  }
  .signup-detail {
    height: 100vh;
    background: #884dff0d;
    .signup-box {
      height: 100%;
    }
    .signup-board {
      border: 1px solid #ef09a1;
      border-radius: 30px;
      .agree {
        cursor: pointer;
        color: white;
        font-size: 0.875rem;
        background-color: #e1bcff;
        background-clip: text;
        -webkit-text-stroke-color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-background-clip: text;
      }
      a.clicking-terms {
        color: white !important;
        &:hover {
          color: #ef09a1 !important;
          cursor: pointer;
        }
      }

      width: 60%;
      .fst-name {
        width: 96%;
        height: 44px;
        border: 1px solid #898c9f;
        border-radius: 30px;
        background: transparent;
        caret-color: white;
        color: white;
        &:focus {
          box-shadow: none;
        }
        &::placeholder {
          font-family: "Abyssinica SIL", serif;
        }
      }
      .last-name {
        width: 96%;
        height: 44px;
        border: 1px solid #898c9f;
        border-radius: 30px;
        background: transparent;
        caret-color: white;
        color: white;
        &:focus {
          box-shadow: none;
        }
        &::placeholder {
          font-family: "Abyssinica SIL", serif;
        }
      }
      .email,
      .password {
        // width: 340px;
        height: 44px;
        border: 1px solid #898c9f;
        border-radius: 30px;
        background: transparent;
        caret-color: white;
        color: white;
        &:focus {
          box-shadow: none;
        }
        &::placeholder {
          font-family: "Abyssinica SIL", serif;
        }
      }
      .day,
      .month,
      .year {
        height: 44px;
        border: 1px solid #898c9f;
        border-radius: 30px;
        background: transparent;
        caret-color: white;
        color: white;
        &:focus {
          box-shadow: none;
        }
        &::placeholder {
          font-family: "Abyssinica SIL", serif;
        }
      }
      .signup-btn {
        button {
          font-family: "Lexend", sans-serif;
          background-color: white;
          border: 1px solid white;
          border-radius: 30px !important;
          &:hover {
            background-color: transparent;
          }
          span {
            background-color: #e1bcff;
            background-clip: text;
            -webkit-text-stroke-color: transparent;
            -webkit-text-stroke-width: 1px;
            -webkit-background-clip: text;
            color: black;
            font-weight: 900;
          }
        }
      }
    }
  }
  .modal-header {
    padding: 0.6rem !important;
  }
  .modal-dialog {
    max-width: 800px;
  }
}

//********subscription********//

.subscription {
  font-family: "Abyssinica SIL", serif;
  background-image: url(./assets/images/big-triangle.svg);

  background-size: 1100px;
  background-repeat: no-repeat;
  background-position: center;

  @import url("https://fonts.googleapis.com/css?family=Montserrat");
  * {
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
  }
  h2 {
    margin: 0;
  }
  /* Body */
  $body-background: linear-gradient(to left, #a55eea, #45aaf2);
  $radius: 20px;
  $table-padding: 20px;
  $break-small: 320px;
  $break-large: 1200px;

  $primary: #4b7bec;
  $echo: #f7b731;
  $pro: #26de81;
  $business: #a55eea;

  @mixin transition($time, $property) {
    -webkit-transition: $time $property ease;
    -ms-transition: $time $property ease;
    transition: $time $property ease;
  }

  /* Card Themes - Start */

  .free-trial-btn {
    &:hover {
      background-image: none !important;
      background-color: $primary !important;
      box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
    }
  }

  .basic {
    .pricing-header {
      background: linear-gradient(#b700a5, #009d8a);
    }
    .price-circle {
      border: 10px solid $primary;
      transition: all 0.4s;
    }
    &:hover {
      .price-circle {
        border-width: 5px;
      }
    }
    .buy-now {
      &:hover {
        background-image: none !important;
        background-color: $primary !important;
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
      }
    }
  }

  .pro {
    .pricing-header {
      // background-color: $pro;
      background: linear-gradient(#b700a5, #009d8a);
    }
    .price-circle {
      border: 10px solid $pro;
      transition: all 0.4s;
    }
    &:hover {
      .price-circle {
        border-width: 5px;
      }
    }
    .buy-now {
      &:hover {
        background-image: none !important;
        background-color: $pro !important;
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
      }
    }
  }
  .business {
    .pricing-header {
      // background-color: $business;
      background: linear-gradient(#b700a5, #009d8a);
    }
    .price-circle {
      border: 10px solid $business;
      transition: all 0.4s;
    }
    &:hover {
      .price-circle {
        border-width: 5px;
      }
    }
    .buy-now {
      &:hover {
        background-image: none !important;
        background-color: $business !important;
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
      }
    }
  }

  .container-fluid {
    max-width: 1200px;
    height: auto;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  .column {
    // flex: 20%;
    padding: 10px;
    width: calc(30.3% - 30px);
    box-sizing: border-box;

    @media screen and (max-width: 980px) {
      flex: 50%;
      display: block;
    }
    @media screen and (max-width: 700px) {
      flex: 100%;
      display: block;
    }
  }

  .pricing-card {
    @include transition(0.4s, background-color);
    height: 500px;
    background-color: white;
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    position: relative;
    transition: all 0.4s;
    &:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
      transform: scale(1.05);
    }

    .popular {
      position: absolute;
      top: 0;
      right: 5%;
      width: auto;
      padding: 10px;
      border-bottom-left-radius: $radius;
      border-bottom-right-radius: $radius;
      background-color: #eb3b5a;
      color: white;
      font-size: 12px;
      z-index: 1;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
    .badge-box {
      padding: 0 40px;
      margin-top: 80px;
      div {
        height: 41px;
      }
      span {
        display: inline-block;
        border: 1px solid $primary;
        padding: 4px 12px;
        border-radius: 25px;
        overflow: hidden;
        color: $primary;
      }
    }

    .pricing-header {
      width: 100%;
      height: 150px;
      position: relative;
      border-radius: $radius $radius 0 0;
      -webkit-border-radius: $radius $radius 0 0;
      -moz-border-radius: $radius $radius 0 0;
      /*border-bottom: 20px solid $primary;*/
      /*
      border-radius: $radius $radius 150px 150px;
      -webkit-border-radius: $radius $radius 150px 150px;
      -moz-border-radius: $radius $radius 150px 150px;
      */

      .plan-title {
        font-size: 24px;
        color: white;
        position: relative;
        top: 25%;
      }

      .price-circle {
        width: calc(33.3% - 30px);
        width: 120px;
        height: 120px;
        border-radius: 100%;
        left: calc(50% - 60px);
        top: 60%;
        background-color: white;
        position: absolute;
        //box-shadow: 1px 3px 5px rgba(0, 0, 0, .4);
        .info {
          display: block;
          font-size: 12px;
          font-weight: bold;
          line-height: 6px;
          color: gray;
        }

        .price-title {
          display: block;
          font-size: 28px;
          padding: 18px 0 0;
          font-weight: bold;
          small {
            font-size: 18px;
          }
        }
      }
      h2 {
        position: relative;
        top: 40%;
        color: #fff;
      }
    }

    .plan-list {
      margin: 10px 0 0 0;
      padding: 0;
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #6b529e;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px #06071b;
      }

      height: 150px;
      .plan-list-items {
        border: none;
        font-size: 0.9rem;
        word-spacing: -2px;
      }
      li {
        list-style-type: none;
        display: block;
        padding: 2px 5px 2px 5px;
        margin: 0;
        border-bottom: 1px solid #f2f2f2;
      }
    }
    .buy-button-box {
      width: 100%;
      float: left;
      margin-top: 30px;

      .buy-now {
        text-decoration: none;
        color: white;
        padding: 10px 30px;
        border-radius: $radius;
        background-image: $body-background;
        margin-top: 20px;
        cursor: pointer;
      }

      .free-trial-btn {
        text-decoration: none;
        color: white;
        padding: 8px 30px;
        border-radius: $radius;
        background-image: $body-background;
        margin-top: -10px;
        cursor: pointer;
      }
    }
  }

  .subscription-heading {
    h1 {
      color: white;
      font-size: 2rem;
      font-family: "Roboto", sans-serif;
    }
    .drp-btn {
      font-family: "Roboto", sans-serif;
      button {
        background: linear-gradient(45deg, #b700a5, #009d8a);
        color: white;
        border-radius: 0px;
        font-weight: bold;
      }
      .dropdown-menu {
        border-radius: 20px;
        width: auto;
        a {
          border-radius: 30px;
          &:hover {
            background-color: #b700a5;
            color: white;
          }
        }
      }
    }
  }
  .subscription-detail {
    height: 650px;
    background: #884dff0d;
    .subscription-box {
      height: auto;
    }
    .subscription-board {
      border: 5px solid #898c9f;
      width: 80%;
      .plan {
        padding: 2px;
        background-color: white;
        p {
          font-size: 1.3rem;
          font-weight: bold;
          text-align: center;
        }
      }
      .plan-list {
        &::-webkit-scrollbar {
          width: 12px;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px #6b529e;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px #06071b;
        }

        height: 300px;
        .plan-list-items {
          border: none;
          font-size: 0.9rem;
          word-spacing: -2px;
        }
      }
      .signup-btn {
        button {
          font-family: "Lexend", sans-serif;
          background-color: white;
          border: none;
          border-radius: 0px;
          span {
            background-color: #e1bcff;
            background-clip: text;
            -webkit-text-stroke-color: transparent;
            -webkit-text-stroke-width: 1px;
            -webkit-background-clip: text;
            color: black;
            font-weight: 900;
          }
        }
      }
    }
  }
}
@media (max-width: 1399.98px) {
  // .chat-bot{
  //   height: 91vh;
  //  }
  //   .chat{
  //     height: 9vh;
  //   }
  .hdr-list-items {
    padding-right: 2px !important;
    padding-left: 2px !important;
    svg {
      margin-top: 0px !important;
    }
  }
  .drp-btn {
    margin-top: 0px !important;
  }
  .hdr-btn {
    margin-top: 0px !important;
  }
  .hdr-list {
    margin: auto 0px !important;
  }
  .type-here-dark,
  .type-here-light {
    .chat-box {
      width: 91% !important;
    }
  }
  .chat-bot-list {
    .chat-bot-list-items {
      padding-top: 0px !important;
      padding-bottom: 0px;
    }
  }
  .chat-bot-list-light {
    .chat-bot-list-items {
      padding-top: 0px !important;
      padding-bottom: 0px;
    }
  }
}
@media (max-width: 1299.98px) {
  //  .chat-bot{
  //   height: 91vh;
  //  }
  //   .chat{
  //     height: 10vh;
  //   }
  .hdr-list-items {
    padding-right: 2px !important;
    padding-left: 2px !important;
    svg {
      margin-top: 0px !important;
    }
  }
  .drp-btn {
    margin-top: 0px !important;
  }
  .hdr-btn {
    margin-top: 0px !important;
  }
  .hdr-list {
    margin: auto 0px !important;
  }
  .type-here-dark,
  .type-here-light {
    width: 92%;
    .chat-box {
      width: 90% !important;
    }
  }
  .chat-bot-list {
    .chat-bot-list-items {
      padding-top: 0px !important;
      padding-bottom: 0px;
    }
  }
  .chat-bot-list-light {
    .chat-bot-list-items {
      padding-top: 0px !important;
      padding-bottom: 0px;
    }
  }
}
// @media(max-width: 1240.98px){
//   .chat-bot{
//     height: 90vh;
//    }
//     .chat{
//       height: 13vh !important;
//     }

// }
@media (max-width: 1199.98px) {
  // .chat-bot{
  //   height: 85vh;
  //  }
  //   .chat{
  //     height: 16vh;
  //   }
  .hdr-list-items {
    padding-right: 2px !important;
    padding-left: 2px !important;
    svg {
      margin-top: 0px !important;
    }
  }
  .drp-btn {
    margin-top: 0px !important;
  }
  .hdr-btn {
    margin-top: 0px !important;
  }
  .hdr-list {
    margin: auto 0px !important;
  }
  .chat-bot-list {
    .chat-bot-list-items {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .type-here-dark,
  .type-here-light {
    .chat-box {
      width: 89% !important;
    }
  }
  .subscription {
    .subscription-detail {
      .subscription-board {
        border: 5px solid #898c9f;
        width: 80%;
        .plan {
          p {
            font-size: 1rem;
          }
          span {
            font-size: smaller;
          }
        }
        .plan-list {
          .plan-list-items {
            font-size: 0.7rem;
          }
        }
      }
    }
  }

  .chat-bot-list-light {
    .chat-bot-list-items {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
@media (max-width: 991.98px) {
  // .chat-bot{
  //   height: 84vh;
  //  }
  //   .chat{
  //     height: 15vh;
  //   }

  .hdr-list-items {
    svg {
      margin-top: 0px !important;
    }
  }
  .drp-btn {
    margin-top: 0px !important;
  }
  .hdr-btn {
    margin-top: 0px !important;
  }
  .hdr-list {
    margin: auto 0px !important;
  }
  .col-md-3 {
    width: 20% !important;
  }

  .type-here-dark,
  .type-here-light {
    width: 85% !important;
    .chat-box {
      width: 85% !important;
    }
  }
  nav {
    .w60 {
      width: 80%;
    }
  }
  nav {
    .hdr-list-items {
      padding: 3px !important;
    }
  }
  .type-here .chat-box {
    width: 84%;
  }

  section {
    .card {
      .card-body {
        .month {
          font-size: 1.25rem;
        }
        .date {
          font-size: 1.25rem;
          letter-spacing: -3px;
        }
        .card-title {
          font-size: 1.25rem;
          letter-spacing: 1px;
        }
        .card-text {
          font-size: 0.625rem;
          letter-spacing: 1px;
        }
      }
    }
  }
  .sign-up {
    .signup-board {
      width: 75% !important;
    }
  }
  .chat-bot-list {
    .chat-bot-list-items {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .chat-bot-list-light {
    .chat-bot-list-items {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .abcd {
    width: 95% !important;
  }
}
@media (max-width: 768.98px) {
  // .chat-bot{
  //   height: 84vh;
  //  }
  //   .chat{
  //     height: 15vh;
  //   }
  .hdr-list-items {
    svg {
      margin-top: 0px !important;
    }
  }
  .drp-btn {
    margin-top: 0px !important;
  }
  .hdr-btn {
    margin-top: 0px !important;
    width: 120px;
  }
  .hdr-list {
    margin: auto 0px;
  }
  .me5 {
    margin-right: 10px !important;
  }
  .type-here-dark,
  .type-here-light {
    width: 85% !important;
    .chat-box {
      width: 81% !important;
    }
  }
  nav {
    .hdr-list-items {
      padding: 1px !important;
    }
  }
  nav {
    .w60 {
      width: 90%;
    }

    .nav-list {
      margin-bottom: 0px !important;
      .nav-list-items {
        a {
          font-size: 14px !important;
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
      }
    }
  }

  .login-page {
    .log-board {
      width: 75% !important;
    }
  }

  .type-here .chat-box {
    width: 79%;
  }
  .chat-bot-list {
    .chat-bot-list-items {
      padding: 2px !important;

      p {
        font-size: 0.7rem;
      }
    }
    span {
      svg {
        display: block;
        width: 18px;
        height: 18px;
      }
    }
  }
  .chat-bot-list-light {
    .chat-bot-list-items {
      padding: 2px !important;

      p {
        font-size: 0.7rem;
      }
    }
    span {
      svg {
        display: block;
        width: 18px;
        height: 18px;
      }
    }
  }

  .dark-answer {
    p {
      color: white;
      font-size: 1rem;
    }
  }
  .light-answer {
    p {
      color: black;
      font-size: 1rem;
    }
  }
  .dark-answer2 {
    p {
      color: white;
      font-size: 1rem;
    }
  }
  .light-answer2 {
    p {
      color: black;
      font-size: 1rem;
    }
  }
  .subscription {
    .subscription-detail {
      .subscription-board {
        border: 5px solid #898c9f;
        width: 80%;
        .plan {
          p {
            font-size: 0.85rem;
          }
          span {
            font-size: smaller;
          }
        }
        .plan-list {
          .plan-list-items {
            font-size: 0.7rem;
          }
        }
      }
    }
  }

  .bg-image {
    background-position: right;
  }

  .information {
    background-size: 1000px;
  }
  section {
    .video {
      .video-image {
        width: 80%;
      }
    }
    .play-btn {
      left: -250px;
    }
    .what-is-this {
      // position: relative;
      // top: -550px;
      .wit-heading {
        p {
          font-size: 0.75rem;
        }
      }
      .startup {
        p {
          font-size: 0.75rem;
        }
      }
    }

    .point-image {
      img {
        width: 130px;
      }
    }
    .card {
      .card-body {
        .month {
          font-size: 1.25rem;
        }
        .date {
          font-size: 1.25rem;
          letter-spacing: -3px;
        }
        .card-title {
          font-size: 1.25rem;
          letter-spacing: 1px;
        }
        .card-text {
          font-size: 0.625rem;
          letter-spacing: 1px;
        }
      }
    }
  }
  .card-section {
    position: relative;
  }
  .abcd {
    width: 90% !important;
  }
}
@media (max-width: 767.98px) {
  // .chat-bot{
  //   height: 84vh;
  //  }
  //   .chat{
  //     height: 15vh;
  //   }
  .description {
    height: 100% !important;
  }
  .abcd {
    width: 95% !important;
  }
  .upcoming-events {
    .upcoming-events-box {
      height: 100% !important;
    }
  }
  nav {
    .w60 {
      width: 100%;
    }
  }
  nav {
    .nav-list {
      .nav-list-items {
        a {
          font-size: 16px !important;
        }
      }
    }
  }
  .chat-bot-list {
    .chat-bot-list-items {
      font-size: 0.8rem !important;
      padding: 0px !important;
    }
    span {
      svg {
        margin-left: 5px;
        display: block;
        width: 15px;
        height: 15px;
      }
    }
  }

  .chat-bot-list-light {
    .chat-bot-list-items {
      font-size: 0.8rem !important;
      padding: 0px !important;
    }
    span {
      svg {
        margin-left: 5px;
        display: block;
        width: 15px;
        height: 15px;
      }
    }
  }
  .bg-image {
    background-position: right;
    background-repeat: no-repeat;
    background-size: 600px;
  }
}
@media (max-width: 575.98px) {
  .chat-bot {
    height: 100vh;
  }

  .dark-question,
  .light-question {
    p {
      font-size: 0.75rem;
    }
  }
  .dark-answer,
  .light-answer {
    p {
      font-size: 0.75rem;
      width: 82% !important;
    }
  }
  .down-text {
    width: 50% !important;
  }
  .heading {
    button {
      margin-left: 5px !important;
    }
    .image1 {
      margin-left: 22px;
      margin-top: 0px;
      width: 100.15px;
      height: 10.9px;
    }
  }
  .logo {
    margin-left: 0px !important;
  }
  .information {
    top: -300px;
  }
  .upcoming-events {
    height: 100%;
  }
  .col-sm-3 {
    width: 27% !important;
  }
  nav {
    .w60 {
      width: 100%;
    }
  }
  .chat {
    padding-top: 1.1rem;
  }

  .sign-up {
    .signup-board {
      padding: 25px !important;
      width: 85% !important;
    }
  }
  .description {
    height: 100% !important;
    background: #884dff0d;
    .description-heading {
      width: 80% !important;
      border: 1px solid #ef09a1de;

      .under-line::before {
        bottom: -36px !important;
      }

      h1 {
        color: white;
      }
      p {
        font-size: 0.8rem;
        color: white;
      }
    }
  }
  .yugen-logo-t {
    .username-dark {
      background-color: white;
      width: 40px;
      height: 40px;
    }
    .username-light {
      background-color: rgb(65, 64, 64);
      width: 40px;
      height: 40px;
      span {
        color: white;
      }
    }
  }
  .type-here-dark,
  .type-here-light {
    width: 90%;
    height: 50px;

    .chat-box {
      width: 92% !important;
      input {
        height: 35px !important;
      }
    }
  }

  .login-page {
    .log-board {
      width: 80% !important;
    }
  }
  .bg-image {
    background-position: top;
    background-repeat: no-repeat;
    background-size: 600px;
  }

  .type-here .chat-box {
    width: 71%;
  }
  .chat-bot-list {
    .chat-bot-list-items {
      font-size: 0.6rem;
      padding: 0px !important;
    }
    span {
      svg {
        margin-left: 5px;
        display: block;
        width: 15px;
        height: 15px;
      }
    }
  }

  .chat-bot-list-light {
    .chat-bot-list-items {
      font-size: 0.6rem;
      padding: 0px !important;
    }
    span {
      svg {
        margin-left: 5px;
        display: block;
        width: 15px;
        height: 15px;
      }
    }
  }
  .information {
    background-size: 1600px;
  }

  .startup {
    width: 75% !important;
  }
  .wit-heading {
    p {
      width: 75% !important;
    }
  }
  .card {
    width: 75% !important;
    margin: auto;
  }
  .accordion-heading {
    p {
      width: 75% !important;
    }
  }
  .abcd {
    width: 75% !important;
  }
  .subscription {
    .subscription-detail {
      .subscription-board {
        border: 5px solid #898c9f;
        width: 80%;
        .plan {
          p {
            font-size: 0.6rem;
          }
          span {
            font-size: 0.55rem;
          }
        }
        .plan-list {
          .plan-list-items {
            font-size: 0.6rem;
          }
        }
      }
    }
  }
}

@media (max-width: 414.98px) {
  .under-line::before {
    top: 32px;
  }
  .under-line2::before {
    left: 20%;
    width: 70%;
    top: 32px;
  }
  .col-sm-3 {
    width: 32% !important;
  }
  .chat-bot-list .chat-bot-list-items p {
    font-size: 0.5rem;
  }
  .chat-bot-list-light .chat-bot-list-items p {
    font-size: 0.5rem;
  }
  .login-page .forgot-password .form-check {
    font-size: 0.9rem;
  }
  .login-page .forgot-password a {
    font-size: 0.9rem;
  }
}
//****iphone12pro******//
@media (max-width: 393.98px) {
  .yugen-logo-t {
    .username-dark {
      background-color: white;
      width: 30px;
      height: 30px;
    }
    .username-light {
      background-color: rgb(65, 64, 64);
      width: 30px;
      height: 30px;
      span {
        color: white;
      }
    }
  }
  .description {
    height: 100% !important;
  }
  section .play-btn {
    left: -211px;
  }

  .hdr-list-items {
    .hdr-btn {
      font-size: 12px;
      width: 100px;
      padding-left: 1px;
      padding-right: 1px;
    }
  }
  .down-text {
    width: 50% !important;
  }
  .heading {
    .image1 {
      margin-top: 10px;
      width: 100.15px;
      height: 10.9px;
    }
  }
  .information {
    top: -300px;
    background-size: 1500px;
  }
  .login-page {
    .log-board {
      padding: 10px !important;
      width: 90% !important;
      .forgot-password {
        .form-check {
          label {
            font-size: 0.8rem;
          }
        }
        a {
          margin: auto 0px;
          font-size: 0.8rem;
        }
      }
    }
  }
  .type-here-dark,
  .type-here-light {
    width: 100% !important;
    .chat-box {
      width: 75% !important;
    }
  }
  .col-4 {
    width: 33.33% !important;
  }
  .chat-bot-list {
    .chat-bot-list-items {
      p {
        font-size: 0.5rem;
      }
    }
  }
  .chat-bot-list-light {
    .chat-bot-list-items {
      p {
        font-size: 0.5rem;
      }
    }
  }
  .abcd {
    width: 95% !important;
  }
}
@media (max-width: 375.98px) {
  .chat-bot {
    .answers {
      top: -50px;
    }
  }

  .heading {
    .image1 {
      margin-top: 10px;
      width: 100.15px;
      height: 10.9px;
    }
  }
  .upcoming-events {
    height: 100%;
  }
  .col-4 {
    width: 33.33% !important;
  }
  nav {
    .nav-list {
      .nav-list-items {
        a {
          font-size: 0.7rem !important;
        }
      }
    }
  }
  nav {
    .w60 {
      width: 100%;
    }
  }
  .chat {
    padding-top: 1.2rem;
  }
  .description {
    height: 100% !important;
    background: #884dff0d;
    .description-heading {
      width: 80% !important;
      border: 1px solid #ef09a1de;

      .about-us-icon {
        svg {
          display: block;
          width: 40px !important;
          height: 60px !important;
        }
      }

      .under-line::before {
        bottom: -33px !important;
      }

      h1 {
        color: white;
      }
      p {
        font-size: 0.8rem;
        color: white;
      }
    }
  }
  .yugen-logo-t {
    .username-dark {
      background-color: white;
      width: 30px;
      height: 30px;
    }
    .username-light {
      background-color: rgb(65, 64, 64);
      width: 30px;
      height: 30px;
      span {
        color: white;
      }
    }
  }
  .type-here-dark,
  .type-here-light {
    width: 90% !important;
    height: 50px;

    .chat-box {
      width: 70% !important;
      input {
        height: 35px !important;
      }
    }
  }
  .sign-up {
    .signup-board {
      width: 90% !important;
    }
  }
  .login-page {
    .log-board {
      padding: 10px !important;
      width: 90% !important;
      .forgot-password {
        .form-check {
          label {
            font-size: 0.8rem;
          }
        }
        a {
          margin: auto 0px;
          font-size: 0.8rem;
        }
      }
    }
  }
  .video {
    .play-btn {
      left: -200px;
    }
  }
  nav {
    .hdr-list-items {
      padding: 1px !important;
      button {
        font-size: 1rem;
      }
    }
  }
  .bg-image {
    background-position: top;
    background-repeat: no-repeat;
    background-size: 360px;
  }
  .accordion-heading {
    p {
      width: 75% !important;
    }
  }
  .abcd {
    width: 95% !important;
  }
  .information {
    top: -300px;
    background-size: 1500px;
  }
  .startup {
    width: 100% !important;
  }

  .wit-heading {
    p {
      width: 100% !important;
    }
  }
  .what-is-this {
    .wit-text {
      width: 100% !important;
    }
  }
  .card-section {
    .card-head {
      width: 100% !important;
    }
  }
  .down-btn {
    .down-text {
      width: 75% !important;
    }
  }

  .dark-answer,
  .light-answer {
    p {
      font-size: 0.52rem;
      width: 82% !important;
    }
  }

  .dark-answer2,
  .light-answer2 {
    p {
      font-size: 0.52rem;
      width: 82% !important;
    }
  }

  .type-here {
    .yugen-logo-t {
      img {
        width: 20px;
        height: 20px;
      }
    }

    width: 100% !important;
    height: 50% !important;
    .chat-box {
      width: 86%;
      input {
        height: 19px;
      }
    }
  }

  .chat-bot-list {
    .chat-bot-list-items {
      padding-right: 0px !important;
      padding-left: 0px !important;
      padding-top: 1px !important;
      padding-bottom: 1px !important;
      p {
        font-size: 0.5rem;
      }
    }
    span {
      svg {
        margin-left: 5px;
        display: block;
        width: 10px;
        height: 10px;
      }
    }
  }
  .chat-bot-list-light {
    .chat-bot-list-items {
      padding-right: 0px !important;
      padding-left: 0px !important;
      padding-top: 1px !important;
      padding-bottom: 1px !important;
      p {
        font-size: 0.5rem;
      }
    }
    span {
      svg {
        margin-left: 5px;
        display: block;
        width: 10px;
        height: 10px;
      }
    }
  }
  .subscription {
    .subscription-detail {
      .subscription-board {
        border: 5px solid #898c9f;
        width: 80%;
        .plan {
          p {
            font-size: 0.5rem;
          }
          span {
            font-size: 0.35rem;
          }
        }
        .plan-list {
          .plan-list-items {
            font-size: 0.4rem;
          }
        }
      }
    }
  }
}
@media (max-width: 320.98px) {
  .chat-bot {
    height: 100vh;
  }

  .faqs {
    position: relative;
    top: 20px;
  }
  .heading {
    h1 {
      font-size: 1.3rem !important;
    }
    button {
      margin-left: 5px !important;
      width: 92px !important;
      height: 31px !important;
      font-size: 1rem !important;
    }
    .image1 {
      margin-left: 0px;
      margin-top: 0px;
      width: 100.15px;
      height: 10.9px;
    }
  }
  .logo {
    margin-left: 0px !important;
    span {
      font-size: 0.9rem !important;
    }
  }
  nav {
    .nav-list {
      .nav-list-items {
        a {
          font-size: 0.7rem !important;
        }
      }
    }
    .hdr-list-items {
      padding: 1px !important;
      button {
        width: 70px !important;
        font-size: 0.7rem;
      }
    }
  }
  .abcd {
    width: 100% !important;
  }
  .upcoming-events {
    height: 100%;
  }
  nav {
    .w60 {
      width: 100%;
    }
  }
  .chat {
    padding-top: 0.5rem;
  }
  .terms-condition {
    .under-line1::before {
      bottom: -33px;
    }
    .under-line::before {
      bottom: -28px;
    }
    &::before {
      bottom: -55px;
      width: 50%;
    }
  }

  .description {
    background: #884dff0d;
    .description-heading {
      width: 80% !important;
      border: 1px solid #ef09a1de;

      .about-us-icon {
        svg {
          display: block;
          width: 40px !important;
          height: 60px !important;
        }
      }

      .under-line::before {
        bottom: -33px !important;
      }

      h1 {
        color: white;
      }
      p {
        font-size: 0.8rem;
        color: white;
      }
    }
  }
  .login-page {
    .log-board {
      .forgot-password {
        .form-check {
          label {
            font-size: 0.7rem;
          }
        }
        a {
          margin: auto 0px;

          font-size: 0.7rem;
        }
      }
    }
  }
  .video {
    .play-btn {
      left: -170px;
    }
  }
  .dark-question,
  .light-question {
    p {
      font-size: 0.75rem;
    }
  }
  .dark-answer,
  .light-answer {
    p {
      font-size: 0.75rem;
      width: 82% !important;
    }
  }

  .dark-answer2,
  .light-answer2 {
    p {
      font-size: 0.47rem;
      width: 82% !important;
    }
  }

  .yugen-logo-q {
    margin-right: 5px;
  }

  .yugen-logo-t {
    .username-dark {
      background-color: white;
      width: 30px;
      height: 30px;
    }
    .username-light {
      background-color: rgb(65, 64, 64);
      width: 30px;
      height: 30px;
      span {
        color: white;
      }
    }
  }
  .type-here {
    width: 100% !important;
    height: 50% !important;
    .chat-box {
      width: 83%;
      input {
        height: 19px;
      }
    }
  }

  .chat-bot-list {
    .chat-bot-list-items {
      padding-right: 0px !important;
      padding-left: 0px !important;
      padding-top: 1px !important;
      padding-bottom: 1px !important;
      p {
        font-size: 0.41rem;
      }
    }
    span {
      svg {
        margin-left: 5px;
        display: block;
        width: 10px;
        height: 10px;
      }
    }
  }
  .chat-bot-list-light {
    .chat-bot-list-items {
      padding-right: 0px !important;
      padding-left: 0px !important;
      padding-top: 1px !important;
      padding-bottom: 1px !important;
      p {
        font-size: 0.41rem;
      }
    }
    span {
      svg {
        margin-left: 5px;
        display: block;
        width: 10px;
        height: 10px;
      }
    }
  }

  .subscription {
    .subscription-detail {
      .subscription-board {
        border: 5px solid #898c9f;
        width: 80%;
        .plan {
          p {
            font-size: 0.45rem;
          }
          span {
            font-size: 0.25rem;
          }
        }
        .plan-list {
          .plan-list-items {
            font-size: 0.3rem;
          }
        }
      }
    }
  }

  .signup-box {
    .signup-board {
      .agree {
        font-size: 0.75rem !important;
      }
    }
  }
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

input[type="date" i] {
  color-scheme: dark;
}

.social-icons-styling{
  height:18px;
  width: 11px;
}
