@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Ethiopic&display=swap');

.ethiopic-font {
  font-family: 'Noto Serif Ethiopic', sans-serif;
}
body {


  background-color: #06071B;
  font-family: 'Abyssinica SIL', serif;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 1016px) {
  .code-ans {
    width: 94% !important;
  }
}
@media screen and (max-width: 992px) {
  .code-ans {
    width: 92% !important;
  }
}
@media screen and (max-width: 768px) {
  .code-ans {
    width: 90% !important;
  }
}