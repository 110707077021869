@media screen and (min-width: 576px) {
  .modal-width {
    max-width: 800px !important;
  }
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem 0.5rem -0.5rem auto;
}

.modal-header1 {
  background: none;
  margin-top: -20px;
  padding: 0;
}
