@charset "UTF-8";
.bg-image {
  background-image: url(./assets/images/skull1.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}

nav {
  height: 100%;
  border-bottom: 0.5px solid white;
  background-color: #06071b !important;
}
nav .w60 {
  width: 72%;
}
nav .nav-container {
  position: relative;
  z-index: 2;
}
nav .navbar-toggler {
  border-color: white !important;
}
nav .navbar-toggler .list-icon {
  color: white !important;
  font-size: 2rem;
}
nav .drp-btn {
  font-family: "Roboto", sans-serif;
}
nav .drp-btn button {
  height: 25px;
  background: rgba(239, 9, 161, 0.8705882353);
  color: white;
  border-radius: 0px;
  font-weight: bold;
  font-size: 0.875rem;
}
nav .drp-btn .dropdown-toggle::after {
  color: white;
  position: relative;
  top: 2px;
}
nav .drp-btn .dropdown-menu {
  border-radius: 20px;
  min-width: 4rem !important;
}
nav .drp-btn .dropdown-menu[data-bs-popper] {
  left: -5px !important;
}
nav .drp-btn .dropdown-menu .dropdown-item {
  font-size: 0.9rem;
}
nav .drp-btn .dropdown-menu a {
  border-radius: 30px;
}
nav .drp-btn .dropdown-menu a:hover {
  background-color: rgba(239, 9, 161, 0.8705882353);
  color: white;
}
nav .tri-logo img {
  width: 150px;
  height: auto;
}
nav .nav-list .nav-list-items a {
  color: white !important;
}
nav .hdr-list-items {
  cursor: pointer;
  font-size: 0.875rem;
  color: white !important;
  border: none;
  background: transparent;
}
nav .hdr-list-items svg {
  display: block !important;
  width: 30px;
}
nav .next {
  cursor: pointer;
}
nav .next svg {
  display: block !important;
  width: 160px;
}
nav .hdr-btn {
  height: 25px;
  background: rgba(239, 9, 161, 0.8705882353);
  color: white;
}

.logo span {
  color: white;
  font-size: 14px;
}

.heading {
  position: relative;
  z-index: 1;
  height: 100vh;
}
.heading .image1 {
  width: 200.15px;
  height: 40.9px;
}
.heading .logo span {
  color: white;
  font-size: 1.2rem;
}
.heading .register-btn {
  width: 120px;
  height: 40px;
  background: rgba(239, 9, 161, 0.8705882353);
  font-size: 1.2rem;
  color: white;
}

.information {
  position: relative;
  z-index: 2;
  top: -300px;
  background-image: url(./assets/images/twotriangle.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1260px;
}

.card-section .up-events {
  cursor: pointer;
}

section .down-btn p {
  color: white;
}
section .down-btn img {
  cursor: pointer;
  width: 50px;
  height: 50px;
}
section .point-image img {
  position: relative;
  z-index: 1;
  width: 220px;
}
section .what-is-this {
  position: relative;
}
section .wit-heading {
  color: white;
}
section .video-image {
  width: 60%;
}
section .play-btn {
  position: relative;
  transform: scaleX(-1);
  left: -340px;
  cursor: pointer;
}
section .text1 {
  height: 15.37109375px;
  width: 148.274230957px;
}
section .card {
  border: none;
  border-radius: 20px !important;
  background: transparent;
  width: 300px;
}
section .card .card-body {
  background: #3f1f55;
  font-family: "Roboto", sans-serif !important;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
section .card .card-body .card-left {
  width: 17%;
}
section .card .card-body .card-right {
  width: 83%;
}
section .card .card-body .month {
  text-transform: uppercase;
  color: #ef09da;
  font-weight: bold;
  font-size: 2rem;
}
section .card .card-body .date {
  font-size: 2rem;
  color: white;
  font-weight: bold;
  letter-spacing: -3px;
}
section .card .card-body .card-title {
  color: white;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 1px;
}
section .card .card-body .card-text {
  font-size: 1rem;
  color: #dfdddd;
  letter-spacing: 1px;
}

.faqs {
  height: 100vh;
  font-family: "Inter", sans-serif;
}
.faqs .accordion-heading {
  font-family: "Roboto", sans-serif;
}
.faqs .accordion-heading h1 {
  color: white;
  font-weight: bold;
}
.faqs .accordion-heading p {
  color: white;
}
.faqs .abcd {
  width: 52%;
  /* Accordion styles */
}
.faqs .abcd h1 {
  margin: 0;
  line-height: 2;
  text-align: center;
  color: white;
}
.faqs .abcd h2 {
  margin: 0 0 0.5em;
  font-weight: normal;
}
.faqs .abcd input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.faqs .abcd .tabs {
  border-radius: 0px;
  overflow: hidden;
}
.faqs .abcd .tab {
  width: 100%;
  color: white;
  overflow: hidden;
}
.faqs .abcd .tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: #06071b;
  cursor: pointer;
  /* Icon */
}
.faqs .abcd .tab-label:hover {
  background: #06071b;
}
.faqs .abcd .tab-label::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
.faqs .abcd .tab-content {
  max-height: 0;
  padding: 0 1em;
  color: white;
  font-size: 0.8rem;
  background: #06071b;
  transition: all 0.35s;
}
.faqs .abcd .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #06071b;
  cursor: pointer;
}
.faqs .abcd .tab-close:hover {
  background: #06071b;
}
.faqs .abcd input:checked + .tab-label {
  background: #06071b;
}
.faqs .abcd input:checked + .tab-label::after {
  transform: rotate(-90deg);
}
.faqs .abcd input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
  color: white;
  font-size: 0.8rem;
}

footer {
  position: relative;
  font-family: "Inter", sans-serif;
  border-top: 1px solid #82838d;
}
footer .footer-heading {
  color: white;
  font-size: 1rem;
}
footer .footer-heading .logo {
  color: white;
  text-decoration: none;
}
footer p {
  color: #82838d;
  font-size: 1rem;
}
footer .description {
  font-size: 12px;
}
footer .list-unstyled li a,
footer .list-unstyled span {
  cursor: pointer;
  color: #82838d;
  text-decoration: none;
}
footer .subscribe-form input {
  background: transparent;
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
footer .subscribe-form button {
  width: 50px;
  height: 50px;
  background: #ff7000;
  border-left: none;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
footer .terms {
  cursor: pointer;
}
footer .terms li {
  background: transparent;
  border: none;
  color: white;
  font-size: 0.875rem;
}
footer .social-icon {
  cursor: pointer;
}
footer .social-icon .social-icon-items {
  width: 40px;
  height: 40px;
  list-style: none;
  border: 2px solid #1f2032 !important;
  border-radius: 50%;
  background: transparent;
  border: none;
  color: white;
}

.under-line::before {
  content: "";
  position: relative;
  display: block;
  left: 5px;
  top: 45px;
  width: 35%;
  height: 5px;
  background-color: rgba(239, 9, 161, 0.8705882353);
}

.under-line1::before {
  content: "";
  position: absolute;
  display: block;
  left: 15px;
  top: 35px;
  width: 15%;
  height: 5px;
  background-color: rgba(239, 9, 161, 0.8705882353);
}

.under-line2::before {
  content: "";
  position: relative;
  display: block;
  left: 30%;
  top: 45px;
  width: 40%;
  height: 5px;
  background-color: rgba(239, 9, 161, 0.8705882353);
}

.privacy {
  position: relative;
  font-family: "Roboto", sans-serif;
  background-image: url(./assets/images/big-triangle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.privacy .privacy-policy-heading h1 {
  color: white !important;
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
}
.privacy .privacy-description {
  height: auto;
  background: rgba(136, 77, 255, 0.0509803922);
}
.privacy .privacy-description .border-privacy {
  padding: 10px;
  border: 1px solid rgba(239, 9, 161, 0.8705882353);
}
.privacy .privacy-description p {
  color: rgba(255, 255, 255, 0.68);
  font-size: 0.75rem;
}
.privacy .privacy-description .privacy-list .privacy-list-items {
  border: none;
  background: transparent;
}
.privacy .privacy-description .privacy-list .privacy-list-items .privacy-heading h3 {
  color: white;
}
.privacy .privacy-description .privacy-list .privacy-list-items p {
  color: white;
}
.privacy .privacy-description .Subscription-Term-heading h1 {
  color: white;
}
.privacy .privacy-description .subscription-term-list .subscription-term-list-items {
  border: none;
  background: transparent;
}
.privacy .privacy-description .subscription-term-list .subscription-term-list-items .subscription-term-heading h3 {
  color: white;
}
.privacy .privacy-description .subscription-term-list .subscription-term-list-items p {
  color: white;
  margin-bottom: 0;
}

.chat-bot-dark {
  background-color: #06071b !important;
}

.chat-bot-light {
  background-color: white;
}

.chat-bot {
  height: calc(100vh - 80px);
}
.chat-bot .answers {
  overflow: auto;
}
.chat-bot .answers::-webkit-scrollbar {
  display: none;
}

.h1 {
  height: 100;
}

.dark-question {
  width: 67%;
  background-color: #311357;
}
.dark-question p {
  color: white;
  font-size: 1rem;
}

.light-question {
  width: 67%;
  background: rgba(5, 3, 32, 0.3490196078);
}
.light-question p {
  color: white;
  font-size: 1rem;
}

.yugen-logo-q .username-dark {
  background-color: white;
  width: 40px;
  height: 40px;
}
.yugen-logo-q .username-light {
  background-color: rgb(65, 64, 64);
  width: 40px;
  height: 40px;
}
.yugen-logo-q .username-light span {
  color: white;
}
.yugen-logo-q img {
  width: 40px;
  height: 40px;
}

.dark-answer {
  width: 67%;
  background: linear-gradient(172.88deg, rgba(17, 215, 191, 0.17) 43.12%, rgba(17, 215, 191, 0) 231%);
}
.dark-answer p {
  color: white;
  font-size: 1rem;
}

.light-answer {
  width: 67%;
  background: linear-gradient(172.88deg, rgba(135, 135, 135, 0.17) 43.12%, rgba(17, 215, 191, 0) 231%);
}
.light-answer p {
  color: black;
  font-size: 1rem;
}

.light-answer2 {
  background: linear-gradient(172.88deg, rgba(135, 135, 135, 0.17) 43.12%, rgba(17, 215, 191, 0) 231%);
  height: 130px;
}
.light-answer2 p {
  color: black;
  font-size: 0.75rem;
}

.dark-answer2 {
  background: linear-gradient(172.88deg, rgba(17, 215, 191, 0.17) 43.12%, rgba(17, 215, 191, 0) 231%);
  height: 130px;
}
.dark-answer2 p {
  color: white;
  font-size: 0.75rem;
}

.yugen-logo-a img {
  width: 40px;
  height: 40px;
}

.chat-bot-list .chat-bot-list-items {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  cursor: pointer;
  border: none;
  background: transparent;
}
.chat-bot-list .chat-bot-list-items p {
  color: white;
  font-size: 0.938rem;
}

.chat-bot-list-light .chat-bot-list-items {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  cursor: pointer;
  border: none;
  background: transparent;
}
.chat-bot-list-light .chat-bot-list-items p {
  color: black !important;
  font-size: 0.938rem;
}
.chat-bot-list-light .chat-bot-list-items span svg .path2 {
  fill: white !important;
}
.chat-bot-list-light .chat-bot-list-items span svg path {
  fill: black;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 60px auto;
  font-size: 5px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #e7e7e7;
  border-right: 1.1em solid #e7e7e7;
  border-bottom: 1.1em solid #e7e7e7;
  border-left: 1.1em solid rgba(239, 9, 161, 0.8705882353);
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.type {
  height: 200px;
}

.loading-dots-dark {
  position: absolute;
  z-index: 5;
  right: 0;
  top: 40%;
  background: transparent;
  width: 50px;
  margin: auto;
}
.loading-dots-dark .loading {
  width: 7px;
  height: 7px;
  background: #fff;
  border-radius: 100%;
  float: left;
  margin-right: 5px;
}
.loading-dots-dark .loading-0 {
  animation: bounce 1s infinite;
  animation-delay: 0.1s;
  background: #ffffff;
}
.loading-dots-dark .loading-1 {
  animation: bounce 1s infinite;
  animation-delay: 0.3s;
  background: #ffffff;
}
.loading-dots-dark .loading-2 {
  animation: bounce 1s infinite ease;
  animation-delay: 0.5s;
  background: #ffffff;
}

.loading-dots-light {
  position: absolute;
  z-index: 115;
  right: 0;
  top: 40%;
  background: transparent;
  width: 50px;
  margin: auto;
}
.loading-dots-light .loading {
  width: 7px;
  height: 7px;
  background: #ffffff;
  border-radius: 100%;
  float: left;
  margin-right: 5px;
}
.loading-dots-light .loading-0 {
  animation: bounce 1s infinite;
  animation-delay: 0.1s;
  background: #070000;
}
.loading-dots-light .loading-1 {
  animation: bounce 1s infinite;
  animation-delay: 0.3s;
  background: #020000;
}
.loading-dots-light .loading-2 {
  animation: bounce 1s infinite ease;
  animation-delay: 0.5s;
  background: #000000;
}

@keyframes bounce {
  0%, 100% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
}
.type-here-dark {
  width: 91%;
  height: 75px;
  background-color: #2d1252;
}
.type-here-dark .chat-box {
  position: relative;
  width: 93%;
}
.type-here-dark .chat-box input {
  width: 90%;
  height: 54px;
  color: white;
  border-radius: 0px !important;
  background-color: #35145e;
  border: none;
  caret-color: white;
}

.yugen-logo-t .username-dark {
  background-color: white;
  width: 53px;
  height: 53px;
}
.yugen-logo-t .username-light {
  background-color: rgb(65, 64, 64);
  width: 53px;
  height: 53px;
}
.yugen-logo-t .username-light span {
  color: white;
}

.type-here-light {
  width: 91%;
  height: 75px;
  background-color: lightgrey;
}
.type-here-light .chat-box {
  width: 93%;
}
.type-here-light .chat-box input {
  width: 90%;
  height: 54px;
  color: black;
  border-radius: 0px !important;
  background-color: lightgrey;
  border: none;
  caret-color: black;
}

.about-us {
  font-family: "Roboto", sans-serif;
  background-image: url(./assets/images/big-triangle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.about-us .about-us-heading h1 {
  color: white;
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
}
.about-us .description {
  height: 84vh;
  background: rgba(136, 77, 255, 0.0509803922);
}
.about-us .description .description-heading {
  border: 1px solid rgba(239, 9, 161, 0.8705882353);
}
.about-us .description .description-heading .about-us-icon svg {
  display: block;
  width: 60px;
  height: 90px;
}
.about-us .description .description-heading .under-line::before {
  content: "";
  position: relative;
  display: block;
  left: 5px;
  bottom: -48px;
  width: 35%;
  height: 5px;
  background-color: rgba(239, 9, 161, 0.8705882353);
}
.about-us .description .description-heading h1 {
  color: white;
  font-size: 2.3rem;
}
.about-us .description .description-heading p {
  font-size: 0.9rem;
  color: white;
}

.payment {
  height: 100vh;
}

.payment-form {
  width: 600px;
  height: 200px;
}

.label-credit {
  color: #afafaf;
}

.payment-bg {
  background-color: #06071b;
  border-radius: 20px;
}

.modal-content {
  background-color: #06071b;
}

.modal-header {
  background: linear-gradient(#b700a5, #009d8a);
}

.modal-title {
  color: white;
  font-family: "Montserrat", sans-serif;
}

.btn-close {
  background-color: white;
}

.payment-title {
  color: white;
  font-family: "Montserrat", sans-serif;
}

.subscription-field {
  background-color: transparent !important;
}

.payment-btn button {
  font-family: "Lexend", sans-serif;
  background-color: white;
  border: 1px solid white;
  border-radius: 30px !important;
}
.payment-btn button:hover {
  background-color: #06071b;
  color: white;
  font-weight: 900;
}
.payment-btn button span {
  background-color: #e1bcff;
  background-clip: text;
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-background-clip: text;
  font-weight: 900;
}

.card1 {
  background-color: white;
  padding: 10px 20px 11px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #afafaf;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}

.form-check-input:checked {
  background-color: rgba(239, 9, 161, 0.8705882353);
  border-color: rgba(239, 9, 161, 0.8705882353);
}

.form-check-input:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(179, 179, 179, 0.25);
}

.pay-button {
  width: 100%;
  color: white;
  font-weight: bold;
  font-size: small;
  background-color: #141650;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  transition: box-shadow 500ms;
  cursor: pointer;
}

.pay-button:disabled {
  background-color: #afafaf;
  box-shadow: none;
  cursor: default;
}

.pay-button:disabled:hover {
  box-shadow: none;
}

.pay-button:hover {
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
}

.forgot-password-btn {
  background-color: #e1bcff;
  background-clip: text;
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-background-clip: text;
  color: black;
  font-weight: 900;
}

.login-page {
  font-family: "Roboto", sans-serif;
  background-image: url(./assets/images/big-triangle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.login-page .invalid-feedback {
  top: 42px;
}
.login-page .login-heading h1 {
  color: white;
  font-size: 2rem;
}
.login-page .login-detail {
  height: 84vh;
  background: rgba(136, 77, 255, 0.0509803922);
}
.login-page .log-board {
  border-radius: 50px;
  height: 100%;
  border: 5px solid #898c9f;
}
.login-page .profile-pic svg {
  width: 70px;
  height: 70px;
}
.login-page .input-group {
  border-radius: 30px !important;
  border: 1px solid #898c9f;
}
.login-page .input-group .input-group-text {
  border-radius: 30px !important;
  height: 46px;
  width: 46px;
  border: none;
  background-color: #ef09a1;
  color: white;
}
.login-page .user-name {
  border: none;
  border-radius: 30px;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  background: transparent;
  caret-color: white;
  color: white;
}
.login-page .user-name:focus {
  box-shadow: none;
}
.login-page .user-name::-moz-placeholder {
  font-family: "Abyssinica SIL", serif;
}
.login-page .user-name::placeholder {
  font-family: "Abyssinica SIL", serif;
}
.login-page .password {
  border: none;
  border-radius: 30px;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  background: transparent;
  caret-color: white;
  color: white;
  background: transparent;
}
.login-page .password:focus {
  box-shadow: none;
}
.login-page .password::-moz-placeholder {
  font-family: "Abyssinica SIL", serif;
}
.login-page .password::placeholder {
  font-family: "Abyssinica SIL", serif;
}
.login-page .forgot-password .form-check {
  color: white;
}
.login-page .forgot-password .form-check .form-check-input:checked {
  background-color: #ef09a1;
  border-color: #ef09a1;
}
.login-page .forgot-password a {
  text-decoration: none;
  color: white;
}
.login-page .forgot-password a:hover {
  text-decoration: underline;
}
.login-page .login-btn button {
  height: 44px;
  font-family: "Abyssinica SIL", serif;
  background-color: #ef09a1;
  color: white;
  border: none;
}

.contact-us {
  font-family: "Roboto", sans-serif;
  background-image: url(./assets/images/big-triangle.svg);
  background-size: 1100px;
  background-repeat: no-repeat;
  background-position: center;
}
.contact-us .contact-us-heading h1 {
  color: white;
  font-size: 2rem;
}
.contact-us .contact-description {
  height: 650px;
  background: rgba(136, 77, 255, 0.0509803922);
}

.user-account {
  font-family: "Roboto", sans-serif;
  height: 700px;
  background-image: url(./assets/images/big-triangle.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.user-account .user-account-heading h1 {
  color: white;
  font-size: 2rem;
}
.user-account .user-account-description {
  height: 650px;
  background: rgba(136, 77, 255, 0.0509803922);
}

.terms-condition {
  font-family: "Roboto", sans-serif;
  background-image: url(./assets/images/big-triangle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.terms-condition::-webkit-scrollbar {
  display: none;
}
.terms-condition .under-line {
  position: relative;
  display: inline-block;
}
.terms-condition .under-line1 {
  position: relative;
  display: inline-block;
}
.terms-condition .under-line1::before {
  content: "";
  position: absolute;
  top: 22px;
  left: 0;
  width: 100%;
  border-bottom: 1px solid rgb(251, 246, 246);
}
.terms-condition .under-line::before {
  content: "";
  position: absolute;
  top: 24px;
  left: 0;
  width: 100%;
  border-bottom: 1px solid rgb(255, 255, 255);
}
.terms-condition .terms-condition-heading h1 {
  color: white;
  font-size: 1.5rem;
}
.terms-condition .terms-condition-description {
  height: auto;
  background: rgba(136, 77, 255, 0.0509803922);
}
.terms-condition .terms-condition-description .border-term {
  padding: 10px;
  border: 1px solid rgba(239, 9, 161, 0.8705882353);
}
.terms-condition .terms-condition-description p {
  color: rgba(255, 255, 255, 0.68);
  font-size: 0.75rem;
}
.terms-condition .terms-condition-description .term-list::-webkit-scrollbar {
  width: 12px;
}
.terms-condition .terms-condition-description .term-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #6b529e;
  border-radius: 10px;
}
.terms-condition .terms-condition-description .term-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #a5a8d8;
}
.terms-condition .terms-condition-description .term-list-items {
  border: none;
  background: transparent !important;
}
.terms-condition .terms-condition-description .term-list-items .Subscription-Term-heading h3 {
  color: white;
  font-size: 1.5rem;
}
.terms-condition .terms-condition-description .term-list-items .term-heading h3 {
  color: white;
  font-size: 1.3rem;
}
.terms-condition .terms-condition-description .term-list-items p {
  color: white;
  margin-bottom: 0;
}
.terms-condition .terms-condition-description .modal-term-list {
  height: 400px;
}
.terms-condition .terms-condition-description .modal-term-list::-webkit-scrollbar {
  width: 12px;
}
.terms-condition .terms-condition-description .modal-term-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #6b529e;
  border-radius: 10px;
}
.terms-condition .terms-condition-description .modal-term-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #a5a8d8;
}
.terms-condition .terms-condition-description .modal-term-list .term-list-items {
  border: none;
  background: transparent !important;
}
.terms-condition .terms-condition-description .modal-term-list .term-list-items .Subscription-Term-heading h3 {
  color: white;
  font-size: 1.5rem;
}
.terms-condition .terms-condition-description .modal-term-list .term-list-items .term-heading h3 {
  color: white;
  font-size: 1.3rem;
}
.terms-condition .terms-condition-description .modal-term-list .term-list-items p {
  color: white;
  margin-bottom: 0;
}
.terms-condition .terms-condition-description .Subscription-Term-heading h1 {
  color: white;
  font-size: 1.5rem;
}
.terms-condition .terms-condition-description .subscription-term-list .subscription-term-list-items {
  border: none;
  background: transparent;
}
.terms-condition .terms-condition-description .subscription-term-list .subscription-term-list-items .subscription-term-heading h3 {
  color: white;
  font-size: 1.3rem;
}
.terms-condition .terms-condition-description .subscription-term-list .subscription-term-list-items p {
  color: white;
  margin-bottom: 0;
}

.upcoming-events {
  font-family: "Roboto", sans-serif;
  background-image: url(./assets/images/big-triangle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.upcoming-events .upcoming-events-heading h1 {
  color: white;
  font-size: 2rem;
}
.upcoming-events .upcoming-events-box {
  height: 84vh !important;
  background: rgba(136, 77, 255, 0.0509803922);
}

.border-radius-0 {
  border-radius: 0 !important;
}

.verify-box {
  height: 100vh !important;
  background: rgba(136, 77, 255, 0.0509803922);
}

.verify-btn {
  height: 44px;
  font-family: "Abyssinica SIL", serif;
  background-color: #ef09a1;
  color: white;
  border: none;
  border-radius: 45px;
}

.verify-btn:hover {
  background-color: #db1d9c !important;
  cursor: pointer;
}

.sign-up {
  font-family: "Roboto", sans-serif;
  background-image: url(./assets/images/big-triangle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.sign-up .sign-up-heading h1 {
  color: white;
  font-size: 2rem;
}
.sign-up .signup-detail {
  height: 100vh;
  background: rgba(136, 77, 255, 0.0509803922);
}
.sign-up .signup-detail .signup-box {
  height: 100%;
}
.sign-up .signup-detail .signup-board {
  border: 1px solid #ef09a1;
  border-radius: 30px;
  width: 60%;
}
.sign-up .signup-detail .signup-board .agree {
  cursor: pointer;
  color: white;
  font-size: 0.875rem;
  background-color: #e1bcff;
  background-clip: text;
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-background-clip: text;
}
.sign-up .signup-detail .signup-board a.clicking-terms {
  color: white !important;
}
.sign-up .signup-detail .signup-board a.clicking-terms:hover {
  color: #ef09a1 !important;
  cursor: pointer;
}
.sign-up .signup-detail .signup-board .fst-name {
  width: 96%;
  height: 44px;
  border: 1px solid #898c9f;
  border-radius: 30px;
  background: transparent;
  caret-color: white;
  color: white;
}
.sign-up .signup-detail .signup-board .fst-name:focus {
  box-shadow: none;
}
.sign-up .signup-detail .signup-board .fst-name::-moz-placeholder {
  font-family: "Abyssinica SIL", serif;
}
.sign-up .signup-detail .signup-board .fst-name::placeholder {
  font-family: "Abyssinica SIL", serif;
}
.sign-up .signup-detail .signup-board .last-name {
  width: 96%;
  height: 44px;
  border: 1px solid #898c9f;
  border-radius: 30px;
  background: transparent;
  caret-color: white;
  color: white;
}
.sign-up .signup-detail .signup-board .last-name:focus {
  box-shadow: none;
}
.sign-up .signup-detail .signup-board .last-name::-moz-placeholder {
  font-family: "Abyssinica SIL", serif;
}
.sign-up .signup-detail .signup-board .last-name::placeholder {
  font-family: "Abyssinica SIL", serif;
}
.sign-up .signup-detail .signup-board .email,
.sign-up .signup-detail .signup-board .password {
  height: 44px;
  border: 1px solid #898c9f;
  border-radius: 30px;
  background: transparent;
  caret-color: white;
  color: white;
}
.sign-up .signup-detail .signup-board .email:focus,
.sign-up .signup-detail .signup-board .password:focus {
  box-shadow: none;
}
.sign-up .signup-detail .signup-board .email::-moz-placeholder, .sign-up .signup-detail .signup-board .password::-moz-placeholder {
  font-family: "Abyssinica SIL", serif;
}
.sign-up .signup-detail .signup-board .email::placeholder,
.sign-up .signup-detail .signup-board .password::placeholder {
  font-family: "Abyssinica SIL", serif;
}
.sign-up .signup-detail .signup-board .day,
.sign-up .signup-detail .signup-board .month,
.sign-up .signup-detail .signup-board .year {
  height: 44px;
  border: 1px solid #898c9f;
  border-radius: 30px;
  background: transparent;
  caret-color: white;
  color: white;
}
.sign-up .signup-detail .signup-board .day:focus,
.sign-up .signup-detail .signup-board .month:focus,
.sign-up .signup-detail .signup-board .year:focus {
  box-shadow: none;
}
.sign-up .signup-detail .signup-board .day::-moz-placeholder, .sign-up .signup-detail .signup-board .month::-moz-placeholder, .sign-up .signup-detail .signup-board .year::-moz-placeholder {
  font-family: "Abyssinica SIL", serif;
}
.sign-up .signup-detail .signup-board .day::placeholder,
.sign-up .signup-detail .signup-board .month::placeholder,
.sign-up .signup-detail .signup-board .year::placeholder {
  font-family: "Abyssinica SIL", serif;
}
.sign-up .signup-detail .signup-board .signup-btn button {
  font-family: "Lexend", sans-serif;
  background-color: white;
  border: 1px solid white;
  border-radius: 30px !important;
}
.sign-up .signup-detail .signup-board .signup-btn button:hover {
  background-color: transparent;
}
.sign-up .signup-detail .signup-board .signup-btn button span {
  background-color: #e1bcff;
  background-clip: text;
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-background-clip: text;
  color: black;
  font-weight: 900;
}
.sign-up .modal-header {
  padding: 0.6rem !important;
}
.sign-up .modal-dialog {
  max-width: 800px;
}

.subscription {
  font-family: "Abyssinica SIL", serif;
  background-image: url(./assets/images/big-triangle.svg);
  background-size: 1100px;
  background-repeat: no-repeat;
  background-position: center;
  @import url("https://fonts.googleapis.com/css?family=Montserrat");
  /* Body */
  /* Card Themes - Start */
}
.subscription * {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
.subscription h2 {
  margin: 0;
}
.subscription .free-trial-btn:hover {
  background-image: none !important;
  background-color: #4b7bec !important;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
}
.subscription .basic .pricing-header {
  background: linear-gradient(#b700a5, #009d8a);
}
.subscription .basic .price-circle {
  border: 10px solid #4b7bec;
  transition: all 0.4s;
}
.subscription .basic:hover .price-circle {
  border-width: 5px;
}
.subscription .basic .buy-now:hover {
  background-image: none !important;
  background-color: #4b7bec !important;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
}
.subscription .pro .pricing-header {
  background: linear-gradient(#b700a5, #009d8a);
}
.subscription .pro .price-circle {
  border: 10px solid #26de81;
  transition: all 0.4s;
}
.subscription .pro:hover .price-circle {
  border-width: 5px;
}
.subscription .pro .buy-now:hover {
  background-image: none !important;
  background-color: #26de81 !important;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
}
.subscription .business .pricing-header {
  background: linear-gradient(#b700a5, #009d8a);
}
.subscription .business .price-circle {
  border: 10px solid #a55eea;
  transition: all 0.4s;
}
.subscription .business:hover .price-circle {
  border-width: 5px;
}
.subscription .business .buy-now:hover {
  background-image: none !important;
  background-color: #a55eea !important;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
}
.subscription .container-fluid {
  max-width: 1200px;
  height: auto;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.subscription .column {
  padding: 10px;
  width: calc(30.3% - 30px);
  box-sizing: border-box;
}
@media screen and (max-width: 980px) {
  .subscription .column {
    flex: 50%;
    display: block;
  }
}
@media screen and (max-width: 700px) {
  .subscription .column {
    flex: 100%;
    display: block;
  }
}
.subscription .pricing-card {
  transition: 0.4s background-color ease;
  height: 500px;
  background-color: white;
  border-radius: 20px;
  position: relative;
  transition: all 0.4s;
}
.subscription .pricing-card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  transform: scale(1.05);
}
.subscription .pricing-card .popular {
  position: absolute;
  top: 0;
  right: 5%;
  width: auto;
  padding: 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #eb3b5a;
  color: white;
  font-size: 12px;
  z-index: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.subscription .pricing-card .badge-box {
  padding: 0 40px;
  margin-top: 80px;
}
.subscription .pricing-card .badge-box div {
  height: 41px;
}
.subscription .pricing-card .badge-box span {
  display: inline-block;
  border: 1px solid #4b7bec;
  padding: 4px 12px;
  border-radius: 25px;
  overflow: hidden;
  color: #4b7bec;
}
.subscription .pricing-card .pricing-header {
  width: 100%;
  height: 150px;
  position: relative;
  border-radius: 20px 20px 0 0;
  -webkit-border-radius: 20px 20px 0 0;
  -moz-border-radius: 20px 20px 0 0;
  /*border-bottom: 20px solid $primary;*/
  /*
  border-radius: $radius $radius 150px 150px;
  -webkit-border-radius: $radius $radius 150px 150px;
  -moz-border-radius: $radius $radius 150px 150px;
  */
}
.subscription .pricing-card .pricing-header .plan-title {
  font-size: 24px;
  color: white;
  position: relative;
  top: 25%;
}
.subscription .pricing-card .pricing-header .price-circle {
  width: calc(33.3% - 30px);
  width: 120px;
  height: 120px;
  border-radius: 100%;
  left: calc(50% - 60px);
  top: 60%;
  background-color: white;
  position: absolute;
}
.subscription .pricing-card .pricing-header .price-circle .info {
  display: block;
  font-size: 12px;
  font-weight: bold;
  line-height: 6px;
  color: gray;
}
.subscription .pricing-card .pricing-header .price-circle .price-title {
  display: block;
  font-size: 28px;
  padding: 18px 0 0;
  font-weight: bold;
}
.subscription .pricing-card .pricing-header .price-circle .price-title small {
  font-size: 18px;
}
.subscription .pricing-card .pricing-header h2 {
  position: relative;
  top: 40%;
  color: #fff;
}
.subscription .pricing-card .plan-list {
  margin: 10px 0 0 0;
  padding: 0;
  height: 150px;
}
.subscription .pricing-card .plan-list::-webkit-scrollbar {
  width: 12px;
}
.subscription .pricing-card .plan-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #6b529e;
  border-radius: 10px;
}
.subscription .pricing-card .plan-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #06071b;
}
.subscription .pricing-card .plan-list .plan-list-items {
  border: none;
  font-size: 0.9rem;
  word-spacing: -2px;
}
.subscription .pricing-card .plan-list li {
  list-style-type: none;
  display: block;
  padding: 2px 5px 2px 5px;
  margin: 0;
  border-bottom: 1px solid #f2f2f2;
}
.subscription .pricing-card .buy-button-box {
  width: 100%;
  float: left;
  margin-top: 30px;
}
.subscription .pricing-card .buy-button-box .buy-now {
  text-decoration: none;
  color: white;
  padding: 10px 30px;
  border-radius: 20px;
  background-image: linear-gradient(to left, #a55eea, #45aaf2);
  margin-top: 20px;
  cursor: pointer;
}
.subscription .pricing-card .buy-button-box .free-trial-btn {
  text-decoration: none;
  color: white;
  padding: 8px 30px;
  border-radius: 20px;
  background-image: linear-gradient(to left, #a55eea, #45aaf2);
  margin-top: -10px;
  cursor: pointer;
}
.subscription .subscription-heading h1 {
  color: white;
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
}
.subscription .subscription-heading .drp-btn {
  font-family: "Roboto", sans-serif;
}
.subscription .subscription-heading .drp-btn button {
  background: linear-gradient(45deg, #b700a5, #009d8a);
  color: white;
  border-radius: 0px;
  font-weight: bold;
}
.subscription .subscription-heading .drp-btn .dropdown-menu {
  border-radius: 20px;
  width: auto;
}
.subscription .subscription-heading .drp-btn .dropdown-menu a {
  border-radius: 30px;
}
.subscription .subscription-heading .drp-btn .dropdown-menu a:hover {
  background-color: #b700a5;
  color: white;
}
.subscription .subscription-detail {
  height: 650px;
  background: rgba(136, 77, 255, 0.0509803922);
}
.subscription .subscription-detail .subscription-box {
  height: auto;
}
.subscription .subscription-detail .subscription-board {
  border: 5px solid #898c9f;
  width: 80%;
}
.subscription .subscription-detail .subscription-board .plan {
  padding: 2px;
  background-color: white;
}
.subscription .subscription-detail .subscription-board .plan p {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
}
.subscription .subscription-detail .subscription-board .plan-list {
  height: 300px;
}
.subscription .subscription-detail .subscription-board .plan-list::-webkit-scrollbar {
  width: 12px;
}
.subscription .subscription-detail .subscription-board .plan-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #6b529e;
  border-radius: 10px;
}
.subscription .subscription-detail .subscription-board .plan-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #06071b;
}
.subscription .subscription-detail .subscription-board .plan-list .plan-list-items {
  border: none;
  font-size: 0.9rem;
  word-spacing: -2px;
}
.subscription .subscription-detail .subscription-board .signup-btn button {
  font-family: "Lexend", sans-serif;
  background-color: white;
  border: none;
  border-radius: 0px;
}
.subscription .subscription-detail .subscription-board .signup-btn button span {
  background-color: #e1bcff;
  background-clip: text;
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-background-clip: text;
  color: black;
  font-weight: 900;
}








#style-1::-webkit-scrollbar
{
	width: 12px;
	background-color: transparent;
}

#style-1::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

#style-1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #191a31;
}














@media (max-width: 1399.98px) {
  .hdr-list-items {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
  .hdr-list-items svg {
    margin-top: 0px !important;
  }
  .drp-btn {
    margin-top: 0px !important;
  }
  .hdr-btn {
    margin-top: 0px !important;
  }
  .hdr-list {
    margin: auto 0px !important;
  }
  .type-here-dark .chat-box,
  .type-here-light .chat-box {
    width: 91% !important;
  }
  .chat-bot-list .chat-bot-list-items {
    padding-top: 0px !important;
    padding-bottom: 0px;
  }
  .chat-bot-list-light .chat-bot-list-items {
    padding-top: 0px !important;
    padding-bottom: 0px;
  }
}
@media (max-width: 1299.98px) {
  .hdr-list-items {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
  .hdr-list-items svg {
    margin-top: 0px !important;
  }
  .drp-btn {
    margin-top: 0px !important;
  }
  .hdr-btn {
    margin-top: 0px !important;
  }
  .hdr-list {
    margin: auto 0px !important;
  }
  .type-here-dark,
  .type-here-light {
    width: 92%;
  }
  .type-here-dark .chat-box,
  .type-here-light .chat-box {
    width: 90% !important;
  }
  .chat-bot-list .chat-bot-list-items {
    padding-top: 0px !important;
    padding-bottom: 0px;
  }
  .chat-bot-list-light .chat-bot-list-items {
    padding-top: 0px !important;
    padding-bottom: 0px;
  }
}
@media (max-width: 1199.98px) {
  .hdr-list-items {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
  .hdr-list-items svg {
    margin-top: 0px !important;
  }
  .drp-btn {
    margin-top: 0px !important;
  }
  .hdr-btn {
    margin-top: 0px !important;
  }
  .hdr-list {
    margin: auto 0px !important;
  }
  .chat-bot-list .chat-bot-list-items {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 5px;
    padding-right: 5px;
  }
  .type-here-dark .chat-box,
  .type-here-light .chat-box {
    width: 89% !important;
  }
  .subscription .subscription-detail .subscription-board {
    border: 5px solid #898c9f;
    width: 80%;
  }
  .subscription .subscription-detail .subscription-board .plan p {
    font-size: 1rem;
  }
  .subscription .subscription-detail .subscription-board .plan span {
    font-size: smaller;
  }
  .subscription .subscription-detail .subscription-board .plan-list .plan-list-items {
    font-size: 0.7rem;
  }
  .chat-bot-list-light .chat-bot-list-items {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (max-width: 991.98px) {
  .hdr-list-items svg {
    margin-top: 0px !important;
  }
  .drp-btn {
    margin-top: 0px !important;
  }
  .hdr-btn {
    margin-top: 0px !important;
  }
  .hdr-list {
    margin: auto 0px !important;
  }
  .col-md-3 {
    width: 20% !important;
  }
  .type-here-dark,
  .type-here-light {
    width: 85% !important;
  }
  .type-here-dark .chat-box,
  .type-here-light .chat-box {
    width: 85% !important;
  }
  nav .w60 {
    width: 80%;
  }
  nav .hdr-list-items {
    padding: 3px !important;
  }
  .type-here .chat-box {
    width: 84%;
  }
  section .card .card-body .month {
    font-size: 1.25rem;
  }
  section .card .card-body .date {
    font-size: 1.25rem;
    letter-spacing: -3px;
  }
  section .card .card-body .card-title {
    font-size: 1.25rem;
    letter-spacing: 1px;
  }
  section .card .card-body .card-text {
    font-size: 0.625rem;
    letter-spacing: 1px;
  }
  .sign-up .signup-board {
    width: 75% !important;
  }
  .chat-bot-list .chat-bot-list-items {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 5px;
    padding-right: 5px;
  }
  .chat-bot-list-light .chat-bot-list-items {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 5px;
    padding-right: 5px;
  }
  .abcd {
    width: 95% !important;
  }
}
@media (max-width: 768.98px) {
  .hdr-list-items svg {
    margin-top: 0px !important;
  }
  .drp-btn {
    margin-top: 0px !important;
  }
  .hdr-btn {
    margin-top: 0px !important;
    width: 120px;
  }
  .hdr-list {
    margin: auto 0px;
  }
  .me5 {
    margin-right: 10px !important;
  }
  .type-here-dark,
  .type-here-light {
    width: 85% !important;
  }
  .type-here-dark .chat-box,
  .type-here-light .chat-box {
    width: 81% !important;
  }
  nav .hdr-list-items {
    padding: 1px !important;
  }
  nav .w60 {
    width: 90%;
  }
  nav .nav-list {
    margin-bottom: 0px !important;
  }
  nav .nav-list .nav-list-items a {
    font-size: 14px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .login-page .log-board {
    width: 75% !important;
  }
  .type-here .chat-box {
    width: 79%;
  }
  .chat-bot-list .chat-bot-list-items {
    padding: 2px !important;
  }
  .chat-bot-list .chat-bot-list-items p {
    font-size: 0.7rem;
  }
  .chat-bot-list span svg {
    display: block;
    width: 18px;
    height: 18px;
  }
  .chat-bot-list-light .chat-bot-list-items {
    padding: 2px !important;
  }
  .chat-bot-list-light .chat-bot-list-items p {
    font-size: 0.7rem;
  }
  .chat-bot-list-light span svg {
    display: block;
    width: 18px;
    height: 18px;
  }
  .dark-answer p {
    color: white;
    font-size: 1rem;
  }
  .light-answer p {
    color: black;
    font-size: 1rem;
  }
  .dark-answer2 p {
    color: white;
    font-size: 1rem;
  }
  .light-answer2 p {
    color: black;
    font-size: 1rem;
  }
  .subscription .subscription-detail .subscription-board {
    border: 5px solid #898c9f;
    width: 80%;
  }
  .subscription .subscription-detail .subscription-board .plan p {
    font-size: 0.85rem;
  }
  .subscription .subscription-detail .subscription-board .plan span {
    font-size: smaller;
  }
  .subscription .subscription-detail .subscription-board .plan-list .plan-list-items {
    font-size: 0.7rem;
  }
  .bg-image {
    background-position: right;
  }
  .information {
    background-size: 1000px;
  }
  section .video .video-image {
    width: 80%;
  }
  section .play-btn {
    left: -250px;
  }
  section .what-is-this .wit-heading p {
    font-size: 0.75rem;
  }
  section .what-is-this .startup p {
    font-size: 0.75rem;
  }
  section .point-image img {
    width: 130px;
  }
  section .card .card-body .month {
    font-size: 1.25rem;
  }
  section .card .card-body .date {
    font-size: 1.25rem;
    letter-spacing: -3px;
  }
  section .card .card-body .card-title {
    font-size: 1.25rem;
    letter-spacing: 1px;
  }
  section .card .card-body .card-text {
    font-size: 0.625rem;
    letter-spacing: 1px;
  }
  .card-section {
    position: relative;
  }
  .abcd {
    width: 90% !important;
  }
}
@media (max-width: 767.98px) {
  .description {
    height: 100% !important;
  }
  .abcd {
    width: 95% !important;
  }
  .upcoming-events .upcoming-events-box {
    height: 100% !important;
  }
  nav .w60 {
    width: 100%;
  }
  nav .nav-list .nav-list-items a {
    font-size: 16px !important;
  }
  .chat-bot-list .chat-bot-list-items {
    font-size: 0.8rem !important;
    padding: 0px !important;
  }
  .chat-bot-list span svg {
    margin-left: 5px;
    display: block;
    width: 15px;
    height: 15px;
  }
  .chat-bot-list-light .chat-bot-list-items {
    font-size: 0.8rem !important;
    padding: 0px !important;
  }
  .chat-bot-list-light span svg {
    margin-left: 5px;
    display: block;
    width: 15px;
    height: 15px;
  }
  .bg-image {
    background-position: right;
    background-repeat: no-repeat;
    background-size: 600px;
  }
}
@media (max-width: 575.98px) {
  .chat-bot {
    height: 100vh;
  }
  .dark-question p,
  .light-question p {
    font-size: 0.75rem;
  }
  .dark-answer p,
  .light-answer p {
    font-size: 0.75rem;
    width: 82% !important;
  }
  .down-text {
    width: 50% !important;
  }
  .heading button {
    margin-left: 5px !important;
  }
  .heading .image1 {
    margin-left: 22px;
    margin-top: 0px;
    width: 100.15px;
    height: 10.9px;
  }
  .logo {
    margin-left: 0px !important;
  }
  .information {
    top: -300px;
  }
  .upcoming-events {
    height: 100%;
  }
  .col-sm-3 {
    width: 27% !important;
  }
  nav .w60 {
    width: 100%;
  }
  .chat {
    padding-top: 1.1rem;
  }
  .sign-up .signup-board {
    padding: 25px !important;
    width: 85% !important;
  }
  .description {
    height: 100% !important;
    background: rgba(136, 77, 255, 0.0509803922);
  }
  .description .description-heading {
    width: 80% !important;
    border: 1px solid rgba(239, 9, 161, 0.8705882353);
  }
  .description .description-heading .under-line::before {
    bottom: -36px !important;
  }
  .description .description-heading h1 {
    color: white;
  }
  .description .description-heading p {
    font-size: 0.8rem;
    color: white;
  }
  .yugen-logo-t .username-dark {
    background-color: white;
    width: 40px;
    height: 40px;
  }
  .yugen-logo-t .username-light {
    background-color: rgb(65, 64, 64);
    width: 40px;
    height: 40px;
  }
  .yugen-logo-t .username-light span {
    color: white;
  }
  .type-here-dark,
  .type-here-light {
    width: 90%;
    height: 50px;
  }
  .type-here-dark .chat-box,
  .type-here-light .chat-box {
    width: 92% !important;
  }
  .type-here-dark .chat-box input,
  .type-here-light .chat-box input {
    height: 35px !important;
  }
  .login-page .log-board {
    width: 80% !important;
  }
  .bg-image {
    background-position: top;
    background-repeat: no-repeat;
    background-size: 600px;
  }
  .type-here .chat-box {
    width: 71%;
  }
  .chat-bot-list .chat-bot-list-items {
    font-size: 0.6rem;
    padding: 0px !important;
  }
  .chat-bot-list span svg {
    margin-left: 5px;
    display: block;
    width: 15px;
    height: 15px;
  }
  .chat-bot-list-light .chat-bot-list-items {
    font-size: 0.6rem;
    padding: 0px !important;
  }
  .chat-bot-list-light span svg {
    margin-left: 5px;
    display: block;
    width: 15px;
    height: 15px;
  }
  .information {
    background-size: 1600px;
  }
  .startup {
    width: 75% !important;
  }
  .wit-heading p {
    width: 75% !important;
  }
  .card {
    width: 75% !important;
    margin: auto;
  }
  .accordion-heading p {
    width: 75% !important;
  }
  .abcd {
    width: 75% !important;
  }
  .subscription .subscription-detail .subscription-board {
    border: 5px solid #898c9f;
    width: 80%;
  }
  .subscription .subscription-detail .subscription-board .plan p {
    font-size: 0.6rem;
  }
  .subscription .subscription-detail .subscription-board .plan span {
    font-size: 0.55rem;
  }
  .subscription .subscription-detail .subscription-board .plan-list .plan-list-items {
    font-size: 0.6rem;
  }
}
@media (max-width: 414.98px) {
  .under-line::before {
    top: 32px;
  }
  .under-line2::before {
    left: 20%;
    width: 70%;
    top: 32px;
  }
  .col-sm-3 {
    width: 32% !important;
  }
  .chat-bot-list .chat-bot-list-items p {
    font-size: 0.5rem;
  }
  .chat-bot-list-light .chat-bot-list-items p {
    font-size: 0.5rem;
  }
  .login-page .forgot-password .form-check {
    font-size: 0.9rem;
  }
  .login-page .forgot-password a {
    font-size: 0.9rem;
  }
}
@media (max-width: 393.98px) {
  .yugen-logo-t .username-dark {
    background-color: white;
    width: 30px;
    height: 30px;
  }
  .yugen-logo-t .username-light {
    background-color: rgb(65, 64, 64);
    width: 30px;
    height: 30px;
  }
  .yugen-logo-t .username-light span {
    color: white;
  }
  .description {
    height: 100% !important;
  }
  section .play-btn {
    left: -211px;
  }
  .hdr-list-items .hdr-btn {
    font-size: 12px;
    width: 100px;
    padding-left: 1px;
    padding-right: 1px;
  }
  .down-text {
    width: 50% !important;
  }
  .heading .image1 {
    margin-top: 10px;
    width: 100.15px;
    height: 10.9px;
  }
  .information {
    top: -300px;
    background-size: 1500px;
  }
  .login-page .log-board {
    padding: 10px !important;
    width: 90% !important;
  }
  .login-page .log-board .forgot-password .form-check label {
    font-size: 0.8rem;
  }
  .login-page .log-board .forgot-password a {
    margin: auto 0px;
    font-size: 0.8rem;
  }
  .type-here-dark,
  .type-here-light {
    width: 100% !important;
  }
  .type-here-dark .chat-box,
  .type-here-light .chat-box {
    width: 75% !important;
  }
  .col-4 {
    width: 33.33% !important;
  }
  .chat-bot-list .chat-bot-list-items p {
    font-size: 0.5rem;
  }
  .chat-bot-list-light .chat-bot-list-items p {
    font-size: 0.5rem;
  }
  .abcd {
    width: 95% !important;
  }
}
@media (max-width: 375.98px) {
  .chat-bot .answers {
    top: -50px;
  }
  .heading .image1 {
    margin-top: 10px;
    width: 100.15px;
    height: 10.9px;
  }
  .upcoming-events {
    height: 100%;
  }
  .col-4 {
    width: 33.33% !important;
  }
  nav .nav-list .nav-list-items a {
    font-size: 0.7rem !important;
  }
  nav .w60 {
    width: 100%;
  }
  .chat {
    padding-top: 1.2rem;
  }
  .description {
    height: 100% !important;
    background: rgba(136, 77, 255, 0.0509803922);
  }
  .description .description-heading {
    width: 80% !important;
    border: 1px solid rgba(239, 9, 161, 0.8705882353);
  }
  .description .description-heading .about-us-icon svg {
    display: block;
    width: 40px !important;
    height: 60px !important;
  }
  .description .description-heading .under-line::before {
    bottom: -33px !important;
  }
  .description .description-heading h1 {
    color: white;
  }
  .description .description-heading p {
    font-size: 0.8rem;
    color: white;
  }
  .yugen-logo-t .username-dark {
    background-color: white;
    width: 30px;
    height: 30px;
  }
  .yugen-logo-t .username-light {
    background-color: rgb(65, 64, 64);
    width: 30px;
    height: 30px;
  }
  .yugen-logo-t .username-light span {
    color: white;
  }
  .type-here-dark,
  .type-here-light {
    width: 90% !important;
    height: 50px;
  }
  .type-here-dark .chat-box,
  .type-here-light .chat-box {
    width: 70% !important;
  }
  .type-here-dark .chat-box input,
  .type-here-light .chat-box input {
    height: 35px !important;
  }
  .sign-up .signup-board {
    width: 90% !important;
  }
  .login-page .log-board {
    padding: 10px !important;
    width: 90% !important;
  }
  .login-page .log-board .forgot-password .form-check label {
    font-size: 0.8rem;
  }
  .login-page .log-board .forgot-password a {
    margin: auto 0px;
    font-size: 0.8rem;
  }
  .video .play-btn {
    left: -200px;
  }
  nav .hdr-list-items {
    padding: 1px !important;
  }
  nav .hdr-list-items button {
    font-size: 1rem;
  }
  .bg-image {
    background-position: top;
    background-repeat: no-repeat;
    background-size: 360px;
  }
  .accordion-heading p {
    width: 75% !important;
  }
  .abcd {
    width: 95% !important;
  }
  .information {
    top: -300px;
    background-size: 1500px;
  }
  .startup {
    width: 100% !important;
  }
  .wit-heading p {
    width: 100% !important;
  }
  .what-is-this .wit-text {
    width: 100% !important;
  }
  .card-section .card-head {
    width: 100% !important;
  }
  .down-btn .down-text {
    width: 75% !important;
  }
  .dark-answer p,
  .light-answer p {
    font-size: 0.52rem;
    width: 82% !important;
  }
  .dark-answer2 p,
  .light-answer2 p {
    font-size: 0.52rem;
    width: 82% !important;
  }
  .type-here {
    width: 100% !important;
    height: 50% !important;
  }
  .type-here .yugen-logo-t img {
    width: 20px;
    height: 20px;
  }
  .type-here .chat-box {
    width: 86%;
  }
  .type-here .chat-box input {
    height: 19px;
  }
  .chat-bot-list .chat-bot-list-items {
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .chat-bot-list .chat-bot-list-items p {
    font-size: 0.5rem;
  }
  .chat-bot-list span svg {
    margin-left: 5px;
    display: block;
    width: 10px;
    height: 10px;
  }
  .chat-bot-list-light .chat-bot-list-items {
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .chat-bot-list-light .chat-bot-list-items p {
    font-size: 0.5rem;
  }
  .chat-bot-list-light span svg {
    margin-left: 5px;
    display: block;
    width: 10px;
    height: 10px;
  }
  .subscription .subscription-detail .subscription-board {
    border: 5px solid #898c9f;
    width: 80%;
  }
  .subscription .subscription-detail .subscription-board .plan p {
    font-size: 0.5rem;
  }
  .subscription .subscription-detail .subscription-board .plan span {
    font-size: 0.35rem;
  }
  .subscription .subscription-detail .subscription-board .plan-list .plan-list-items {
    font-size: 0.4rem;
  }
}
@media (max-width: 320.98px) {
  .chat-bot {
    height: 100vh;
  }
  .faqs {
    position: relative;
    top: 20px;
  }
  .heading h1 {
    font-size: 1.3rem !important;
  }
  .heading button {
    margin-left: 5px !important;
    width: 92px !important;
    height: 31px !important;
    font-size: 1rem !important;
  }
  .heading .image1 {
    margin-left: 0px;
    margin-top: 0px;
    width: 100.15px;
    height: 10.9px;
  }
  .logo {
    margin-left: 0px !important;
  }
  .logo span {
    font-size: 0.9rem !important;
  }
  nav .nav-list .nav-list-items a {
    font-size: 0.7rem !important;
  }
  nav .hdr-list-items {
    padding: 1px !important;
  }
  nav .hdr-list-items button {
    width: 70px !important;
    font-size: 0.7rem;
  }
  .abcd {
    width: 100% !important;
  }
  .upcoming-events {
    height: 100%;
  }
  nav .w60 {
    width: 100%;
  }
  .chat {
    padding-top: 0.5rem;
  }
  .terms-condition .under-line1::before {
    bottom: -33px;
  }
  .terms-condition .under-line::before {
    bottom: -28px;
  }
  .terms-condition::before {
    bottom: -55px;
    width: 50%;
  }
  .description {
    background: rgba(136, 77, 255, 0.0509803922);
  }
  .description .description-heading {
    width: 80% !important;
    border: 1px solid rgba(239, 9, 161, 0.8705882353);
  }
  .description .description-heading .about-us-icon svg {
    display: block;
    width: 40px !important;
    height: 60px !important;
  }
  .description .description-heading .under-line::before {
    bottom: -33px !important;
  }
  .description .description-heading h1 {
    color: white;
  }
  .description .description-heading p {
    font-size: 0.8rem;
    color: white;
  }
  .login-page .log-board .forgot-password .form-check label {
    font-size: 0.7rem;
  }
  .login-page .log-board .forgot-password a {
    margin: auto 0px;
    font-size: 0.7rem;
  }
  .video .play-btn {
    left: -170px;
  }
  .dark-question p,
  .light-question p {
    font-size: 0.75rem;
  }
  .dark-answer p,
  .light-answer p {
    font-size: 0.75rem;
    width: 82% !important;
  }
  .dark-answer2 p,
  .light-answer2 p {
    font-size: 0.47rem;
    width: 82% !important;
  }
  .yugen-logo-q {
    margin-right: 5px;
  }
  .yugen-logo-t .username-dark {
    background-color: white;
    width: 30px;
    height: 30px;
  }
  .yugen-logo-t .username-light {
    background-color: rgb(65, 64, 64);
    width: 30px;
    height: 30px;
  }
  .yugen-logo-t .username-light span {
    color: white;
  }
  .type-here {
    width: 100% !important;
    height: 50% !important;
  }
  .type-here .chat-box {
    width: 83%;
  }
  .type-here .chat-box input {
    height: 19px;
  }
  .chat-bot-list .chat-bot-list-items {
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .chat-bot-list .chat-bot-list-items p {
    font-size: 0.41rem;
  }
  .chat-bot-list span svg {
    margin-left: 5px;
    display: block;
    width: 10px;
    height: 10px;
  }
  .chat-bot-list-light .chat-bot-list-items {
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .chat-bot-list-light .chat-bot-list-items p {
    font-size: 0.41rem;
  }
  .chat-bot-list-light span svg {
    margin-left: 5px;
    display: block;
    width: 10px;
    height: 10px;
  }
  .subscription .subscription-detail .subscription-board {
    border: 5px solid #898c9f;
    width: 80%;
  }
  .subscription .subscription-detail .subscription-board .plan p {
    font-size: 0.45rem;
  }
  .subscription .subscription-detail .subscription-board .plan span {
    font-size: 0.25rem;
  }
  .subscription .subscription-detail .subscription-board .plan-list .plan-list-items {
    font-size: 0.3rem;
  }
  .signup-box .signup-board .agree {
    font-size: 0.75rem !important;
  }
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

input[type=date i] {
  color-scheme: dark;
}/*# sourceMappingURL=style.css.map */